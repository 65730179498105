import React from 'react';
import appGoogleTracking from '../../../modules/googletracking';

const footerlogoV1 = () => (
    <div className="footer__logo">
        <p className="footer__logo__text">
                FOLGE DEINEM GESCHMACK
        </p>
        <p className="footer__logo__text2">
                FOLGE
        <a 
        href="https://www.milka.de/" 
        target="_blank" 
        rel="noopener noreferrer" 
        title="Milka"
        data-event="go_to_milka"
        data-category="Footer"
        data-action="Go to Page"
        data-label="MILKA_ALLPAGE_FOOTER"
        onClick={appGoogleTracking.processEventCTA}> <span className="icon-milka-logo" ></span></a>
            AUF SOCIAL MEDIA
        </p>
    </div>
);

export default footerlogoV1;