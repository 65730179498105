import React from 'react';
import FooterLogoV1 from './FooterContent/FooterLogoV1';
import FooterSocialV1 from './FooterContent/FooterSocialV1';
import FooterCopyrightV1 from './FooterContent/FooterCopyrightV1';
import FooterLinksV1 from './FooterContent/FooterLinksV1';
import appGoogleTracking from '../../modules/googletracking';

const footerV1 = () => (
    <footer className="footer__container_v1">
        <div className="grid-row footer__head_v1">
            <FooterLogoV1 />
            <FooterSocialV1 />
            <FooterCopyrightV1 />
            <FooterLinksV1 />
            <div className='js-scroll-top'/>
            <a className="milka__logo__back" 
            href="https://www.milka.de/" 
            target="_blank" 
            rel="noopener noreferrer" 
            title="Milka"
            data-event="go_to_milka"
            data-category="Footer"
            data-action="Go to Page"
            data-label="MILKA_ALLPAGE_FOOTER"
               onClick={appGoogleTracking.processEventCTA}
            >
                 <span className="icon-milka-logo"></span></a>
        </div>
    </footer>
); 

export default footerV1;