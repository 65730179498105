import React, { Component } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import Aux from "../../hoc/Auxiliare";
import GLOBAL_CONFIG from "../../config/config";
import appGoogleTracking from "../../modules/googletracking";
import TitleContent from "../../components/Content/TitleContent/TitleContent";
import GiftContent from "../../components/Content/GiftContent/GiftContent";
import GiftContentFooter from "../../components/Content/GiftContent/GiftContentFooter";
import SliderContent from "../../components/Content/SliderContent/SliderContent";
import { mainLoaderToggle, timeStamp } from "../../modules/helpers";
import StepContent from "../../components/Content/StepContent/StepContent";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prizeAvailableError: "",
    };
  }
  componentDidMount() {
    const { t } = this.props;
    //Remove main loader
    mainLoaderToggle("hide");
    const elementSelector = document.querySelectorAll('.s-content-title p a'),
      elementSelectorLength = elementSelector.length;

    for (let i = 0; i < elementSelectorLength; i++) {
      elementSelector[i].addEventListener('click', function (event) {
        appGoogleTracking.dataLayerPush({
          'dataEvent': 'upload_receipt_link',
          'dataCategory': 'Start Page',
          'dataAction': 'Click on upload receipt link',
          'dataLabel': 'GAME_SP_PART'
        });
      });
    }
    //Virtual page
    appGoogleTracking.dataLayerPush({
      customEvent: {
        event: "virtual-page",
        virtualUri: GLOBAL_CONFIG.Route.home,
        pageTitle: t("Meta.title"),
      },
    });
  }

  componentWillUnmount() {
    //Show mainloader
    mainLoaderToggle("show");
  }

  render() {
    const { t } = this.props;
    return (
      <Aux>
        <div
          className={`content-home content-home__${window.PROMOTION_CAMPAIGN}`}
        >
          <TitleContent
            heading={
              typeof t("Page.Home.titl") === "string"
                ? parse(t("Page.Home.title"))
                : t("Page.Home.tite")
            }
            subheading={
              typeof t("Page.Home.subTitle") === "string"
                ? parse(t("Page.Home.subTitle"))
                : t("Page.Home.subTitle")
            }
            // paragraph={
            //   typeof t("Page.Home.paragraph") === "string"
            //     ? parse(t("Page.Home.paragraph"))
            //     : t("Page.Home.paragraph")
            // }
          />
          <NavLink to={GLOBAL_CONFIG.Route.participation} exact 
            data-event="join_now" 
            data-category="Start Page" 
            data-action="Click on Join Now" 
            data-label="GAME_SP_PART"
            onClick={appGoogleTracking.processEventCTANavLink}
            className="btn__container btn--primary btn--hover btn__mitmachen">
                <span className="btn__text">{parse(t('Page.Home.button'))}</span>
          </NavLink>
          {this.SpecificSection(this.props)}
          
        </div>
      </Aux>
    );
  }

  SpecificSection(props) {
    let { t, prizeAvailable } = props;

    switch (window.PROMOTION_CAMPAIGN) {
      case "rewe":
        return this.Rewe(t);
        break;
      case "kaufland":
        return this.Kaufland(t);
        break;
      case "muller":
        return this.Muller(t);
        break;
      case "edeka":
        return this.Edeka(t);
        break;
      case "netto":
        return this.Netto(t);
        break;
      case "budni":
        return this.Budni(t);
        break;
        case "xmas":
        return this.xmas(t);
        break;
      default:
        break;
    }

    return null;
  }

 



 

  // verifyEdekaPrizeAvailable(prizeAvailable){
  //   if(!prizeAvailable){
  //     this.setState({
  //       prizeAvailableError: 'Die Maximale Anzahl der Teilnahmen wurde erreicht. Freue dich auf viele weitere Aktionen, die kommen werden.'
  //     })
  //   }
  //   else{
  //     window.location.href = '/mitmachen'
  //   }
  // }




  xmas(t) {
    const image = {
      desktop: "/resources/images/budni/participation-img-specific.png",
      mobile: "/resources/images/budni/participation-img-specific-mobile.png",
    };
    return (
      <>
        <StepContent title={t("Page.Home.stepTitle")} t={t} />
        <SliderContent />
        <div className="content-home-footer">
          <GiftContentFooter hrShow={true} classModifier="no-back" />
        </div>
      </>
    );
  }
}

export default withTranslation()(Home);
