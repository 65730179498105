import React from 'react';
import appGoogleTracking from '../../../modules/googletracking';

/*
    Google tagging plan added on links using : appGoogleTracking.processEventCTA
*/

const footerlinksV1 = () => (
    <div className="footer__legal_v1">
        <div className='group__legal__link'>
            <span className="legal__link">
                <a className="js-event-cta"
                 href="https://www.milka.de/Static/bedingungen-zur-nutzung"
                 target="_blank"
                 rel="noopener noreferrer"
                 title="BEDINGUNGEN ZUR NUTZUNG"
                 data-event="footer_contact_menu"
                 data-category="Footer"
                 data-action="Select Menu"
                 data-label="BEDINGUNGEN ZUR NUTZUNG" 
                 onClick={appGoogleTracking.processEventCTA}>bedingungen zur nutzung</a>
            </span>
            <span className="legal__link">
                <a className="js-event-cta"
                  href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&amp;siteID=7GTws0jSEtgtqGQHH57lZw%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="DATENSCHUTZERKLÄRUNG"
                  data-event="footer_contact_menu"
                  data-category="Footer"
                  data-action="Select Menu"
                  data-label="DATENSCHUTZERKLÄRUNG"
                  onClick={appGoogleTracking.processEventCTA}>datenschutzerklärung</a>
            </span>
            <span className="legal__link">
                <a className="js-event-cta"
                 href="https://www.milka.de/Static/impressum" 
                 title="IMPRESSUM" 
                 target="_blank" 
                 rel="noopener noreferrer" 
                 data-event="footer_contact_menu" 
                 data-category="Footer" 
                 data-action="Select Menu" 
                 data-label="NEUES" 
                 onClick={appGoogleTracking.processEventCTA}>IMPRESSUM</a>
            </span>
            <span className="legal__link">
                <a className="js-event-cta"
                 href="https://www.milka.de/Static/nutzungsbasierte-online-werbung" 
                 target="_blank" 
                 rel="noopener noreferrer" 
                 title=" NUTZUNGSBASIERTE ONLINE-WERBUNG" 
                 data-event="footer_contact_menu" 
                 data-category="Footer" 
                 data-action="Select Menu" 
                 data-label="NUTZUNGSBASIERTE ONLINE-WERBUNG" 
                 onClick={appGoogleTracking.processEventCTA}>nutzungsbasierte online-werbung</a>
            </span>
        </div>
        <div className='group__legal__link2'>
            <span className="legal__link">
                <a className="js-event-cta" 
                href="https://milka.de/static/allgemeine-geschaftsbedingungen-new" 
                target="_blank" 
                rel="noopener noreferrer" 
                title=" NUTZUNGSBASIERTE ONLINE-WERBUNG" 
                data-event="footer_contact_menu" 
                data-category="Footer" 
                data-action="Select Menu" 
                data-label="NUTZUNGSBASIERTE ONLINE-WERBUNG" 
                onClick={appGoogleTracking.processEventCTA}>allgemeine geschäftsbedingungen</a>
            </span>
            <span className="legal__link">
                <a className="js-event-cta" 
                href="https://www.milka.de/Static/mondelez-international" 
                target="_blank" 
                rel="noopener noreferrer" 
                title="MONDELEZ INTERNATIONAL" 
                data-event="footer_contact_menu" 
                data-category="Footer" 
                data-action="Select Menu" 
                data-label="MONDELEZ INTERNATIONAL" 
                onClick={appGoogleTracking.processEventCTA}>mondelez international</a>
            </span>
            <span className="legal__link">
                <a className="js-event-cta" 
                href="/cookie" 
                title=" COOKIE RICHTLINIE" 
                data-event="footer_contact_menu" 
                data-category="Footer" 
                data-action="Select Menu" 
                data-label="COOKIE RICHTLINIE" 
                onClick={appGoogleTracking.processEventCTA}>cookie richtlinie</a>
            </span>
            <span className="legal__link">
                <a className="js-event-cta" 
                href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" 
                target="_blank" 
                rel="noopener noreferrer" 
                title="Kontakt" 
                data-event="footer_contact_menu" 
                data-category="Footer" 
                data-action="Select Menu" 
                data-label="Kontakt" 
                onClick={appGoogleTracking.processEventCTA}>Kontakt</a>
            </span>
            <span className="legal__link">
                <a className="js-event-cta" 
                href="https://milka.de/Static/faq" 
                target="_blank" 
                rel="noopener noreferrer" 
                title="FAQ" 
                data-event="footer_contact_menu" 
                data-category="Footer" 
                data-action="Select Menu" 
                data-label="FAQ" 
                onClick={appGoogleTracking.processEventCTA}>Faq</a>
            </span>
        </div>
    </div>      
);

export default footerlinksV1;