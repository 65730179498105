import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import GLOBAL_CONFIG from '../../config/config';
import appGoogleTracking from '../../modules/googletracking';
import { mainLoaderToggle } from '../../modules/helpers';
import { NavLink } from 'react-router-dom';
import ThankYou from '../ThankYou/ThankYou';

class End extends Component {

	constructor(props) {
		super(props)

		// to check if the device is a mobile or not 
		this.state = {
			isMobile: false
		}

		this.resize = this.resize.bind(this);
	}

	componentDidMount() {
		const { t } = this.props;
		//Remove main loader
		mainLoaderToggle('hide');

		//Virtual page
		appGoogleTracking.dataLayerPush({
			'customEvent': {
				'event': 'virtual-page',
				'virtualUri': GLOBAL_CONFIG.Route.end,
				'pageTitle': t('Meta.title')
			}
		});

		window.addEventListener("resize", this.resize);
		this.resize();
	}

	componentWillUnmount() {
		//Show mainloader
		mainLoaderToggle('show');
	}

	resize() {
		// set isMobile to true if the device is a mobile and false otherwise 
		this.setState({ isMobile: window.innerWidth <= 768 });
	}

	render() {

		const { t } = this.props;

		return (
			<div className="s-card-container s-content-end">
				<ThankYou />
				{/* <h1>{parse(t('Page.End.title') || '')}</h1>
				<h2>{parse(t('Page.End.subtitle') || '')}</h2> */}
				{/* <img src={parse(t('Page.End.CardContent1.imgSrc') || '')} /> */}
				{/* <NavLink to={GLOBAL_CONFIG.Route.home} exact data-event="info-click" data-category="Informational Action" data-action="Start Page" data-label={window.META_TITLE} onClick={appGoogleTracking.processEventCTANavLink}>
					<div className="btn__container btn--secondary-lilac btn--hover">
						<span className="btn__text">{this.state.isMobile ?  parse(t('Page.End.link.mobile')): parse(t('Page.End.link.desktop'))}</span>
					</div>
				</NavLink> */}
			</div>
		);
	}

}

export default withTranslation()(End);



