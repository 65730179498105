const _LOCAL_CAPTCHA = {
    local: "6Lct-o4oAAAAAJTCtZTlhefMH7DRVHBO2SYkUNPF",
    rewe: {
        key: "6Lct-o4oAAAAAJTCtZTlhefMH7DRVHBO2SYkUNPF",
        lang: "de"
    },
    kaufland: {
        key: "6Lct-o4oAAAAAJTCtZTlhefMH7DRVHBO2SYkUNPF",
        lang: "de"
    },
    muller: {
        key: "6LfWEs8ZAAAAABdXUIRvUbcVHid8KRgbfAQGvZtA",
        lang: "de"
    },
    edeka: {
        key: "6Lct-o4oAAAAAJTCtZTlhefMH7DRVHBO2SYkUNPF",
        lang: "de"
    },
    xmas: {
        key: "6LcfvCQqAAAAAKNmOXABJvz2fRdF0sUN7sm0lWHG",
        lang: "de"
    },
    netto: {
        key: "6Lct-o4oAAAAAJTCtZTlhefMH7DRVHBO2SYkUNPF",
        lang: "de"
    },
    budni: {
        key: "6Lct-o4oAAAAAJTCtZTlhefMH7DRVHBO2SYkUNPF",
        lang: "de"
    },
}

export default _LOCAL_CAPTCHA;