import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser'; 
import GLOBAL_CONFIG from '../../config/config'; 
import appGoogleTracking from '../../modules/googletracking';
import { mainLoaderToggle, navParticipationMenu, scrollToElement } from '../../modules/helpers';
import TitleContent from '../../components/Content/TitleContent/TitleContent';
import GiftContentFooter from '../../components/Content/GiftContent/GiftContentFooter';

class Confirmation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            winTier: null,
            winText: '',
            winImage: ''
        }
    }

    // componentWillMount(){
    //     if(sessionStorage.getItem(GLOBAL_CONFIG.Session.userstatus) === null && sessionStorage.getItem(GLOBAL_CONFIG.Session.userstatus)  !== 'participate'){
    //         //Go to particaption page
    //         this.props.history.push({pathname: GLOBAL_CONFIG.Route.participation});
    //         window.location.reload();
    //     } else {
    //         sessionStorage.removeItem(GLOBAL_CONFIG.Session.userstatus)
    //     }
    // }

    componentDidMount(){
        window.scrollTo(0,1000)
        const { t } = this.props;
        //Active menu participation - mitmachen
        navParticipationMenu(true);

        //Scroll to navigation menu
        

        //Remove main loader
        mainLoaderToggle('hide');

        //Virtual page
        // appGoogleTracking.dataLayerPush({
        //     'customEvent': {
        //         'event': 'virtual-page',
        //         'virtualUri': GLOBAL_CONFIG.Route.thankyou,
        //         'pageTitle': t('Meta.title'),
        //         'ParticipantId' : window.PARTICIPATION_ID
        //     }
        // });

        const sessionData = sessionStorage.getItem("userinfo");

        const parsedData = JSON.parse(sessionData);

        const winTier = parsedData.WinTier;

        const tierMapping = {
            1: { text: 'einen TUI Online-Reisegutschein im Wert von 3.000€', image: '/resources/images/xmas/prize-1.png' },
            2: { text: 'einen Händlergutschein im Wert von 5€', image: '/resources/images/xmas/prize-2.png' },
            3: { text: 'einen Händlergutschein im Wert von 10€', image: '/resources/images/xmas/prize-3.png' },
            4: { text: 'einen Händlergutschein im Wert von 50€', image: '/resources/images/xmas/prize-4.png' },
            5: { text: 'einen YETI®-Cups Rambler Strohhalmbecher', image: '/resources/images/xmas/prize-5.png' },
            6: { text: '1 Paar trendige Socken im Milka Design', image: '/resources/images/xmas/prize-6.png' },
            7: { text: 'einen Rabatt-Coupon auf deine nächste Reise mit TUI', image: '/resources/images/xmas/prize-7.png' },
            8: { text: 'einen Händlergutschein im Wert von 5€', image: '/resources/images/xmas/prize-2.png' },
            9: { text: 'einen Händlergutschein im Wert von 10€', image: '/resources/images/xmas/prize-3.png' },
            10: { text: 'einen Händlergutschein im Wert von 50€', image: '/resources/images/xmas/prize-4.png' }
        }

        // Set the win text and image based on the retrieved winTier
        if (winTier && tierMapping[winTier]) {
            this.setState({
              winTier,
              winText: tierMapping[winTier].text,
              winImage: tierMapping[winTier].image
            });
          }
    }

    componentWillUnmount(){
        //Remove active menu participation - mitmachen
        scrollToElement();

        //Show mainloader
        mainLoaderToggle('show');
    }

    DisplayCards(t){
        const boxs = t("Page.Confirmation.boxs", { returnObjects: true });
        return (
            <div className="s-card-container">
                {
                    boxs.map(({img, platzhalter, title, copy, button}) =>(
                        <div className="s-card">
                            <img src={img} alt=""/>
                            { platzhalter ? (<img src={platzhalter} alt="" className="platzhalter"/>) : null}
                            <div className="s-card__copy">
                                <h3>{parse(title)}</h3>
                                <p>{parse(copy)}</p>
                                <a href={button.href} target="_blank">
                                    <div className="btn__container btn--secondary-lilac btn--hover">
                                        <span className="btn__text"> {button.text} </span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }

    DisplayButton() {
        if (window.PROMOTION_CAMPAIGN === "edeka") {
            return null;
        }
        return (
            <div className='s-content-button'>
                <a
                    href="/"
                    target="_blank"
                    rel="noopener noreferrer"
                //   data-event="info-click"
                //   data-category="Informational Action"
                //   data-action="See Product"
                //   data-label=""
                //   onClick={appGoogleTracking.processEventCTA}
                >
                    <button className={`btn__container btn--secondary-lilac btn--hover ${window.PROMOTION_CAMPAIGN === "kaufland" ? "button__kaufland" : ""}`}>{window.PROMOTION_CAMPAIGN === "kaufland" ? "Entdecke Mehr von Milka" : "Mehr entdecken"}</button>
                </a>
            </div>
        );
    }
    render() { 
        const { t } = this.props;

        const {winText, winImage} = this.state

        let options = {
            btnShow: false,
            hrShow: false
        };

        return (
            <div className={`content-confirmation content-confirmation__${window.PROMOTION_CAMPAIGN}`}>
                <TitleContent 
                    heading={parse(t('Page.GameConfirmation.title'))}
                    />
                    <div  className='s-content-paragrah'>
                        <p>Du hast an der Milka Weihnachtsaktion teilgenommen und {winText} gewonnen!* <br/> Für weitere Informationen zu deinem Gewinn wirst du per E-Mail benachrichtigt.</p>
                    </div>
                    <div className='s-content-prize'>
                        <div className='s-content-gift' >
                            <img src={winImage} alt='Prize'/>
                        </div>
                    </div>  
                    <div className='s-content-title'>
                            <p style={{fontSize: '16px'}}>*Vorbehaltlich der Prüfung des Kaufbelegs und der Einhaltung der Teilnahmebedingungen</p>
                    </div>
                    <div className='tender-gift'>
						<div className='s-content-title'>
							<h1>Milka – das zarteste Geschenk</h1>  
						</div>
						{/* <img src={'/resources/images/xmas/geschenk-holding.png'} alt="Milka tender gift" /> */}
						<img src={t('Page.GameConfirmation.tenderGift')} alt="Milka tender gift" />
					</div>
					<a href='https://das-zarteste-geschenk.milka.de/' target='_blank' 
						data-event="give_now" 
						data-category="Confirmation Page" 
						data-action="Click on Give Now" 
						data-label="GAME_CP_PART" 
						onClick={appGoogleTracking.processEventCTANavLink}>
						<div className={window.PROMOTION_CAMPAIGN === "netto" ? "btn__container btn--primary btn--hover" : "btn__container btn--secondary-lilac btn--hover"}>
							<span className="btn__text">{parse(t('Page.GameConfirmation.button'))}</span>
						</div>
					</a>
					<hr className="purple-line-bottom-text"></hr>
					<GiftContentFooter options={options} />
            </div>
        );
    }
}

export default withTranslation()(Confirmation);