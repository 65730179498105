import React from 'react';
import Aux from '../../hoc/Auxiliare';
import _LOCAL_DATE from '../../Models/CampaignDate';
import GLOBAL_CONFIG from '../../config/config';

const banner = (props) => {

    let {campaignStatus, promotionCampaign} = props,
        status = campaignStatus,
        mobileImage = `/resources/images/${promotionCampaign}/banner-mobile.png`,
        desktopImage = `/resources/images/${promotionCampaign}/banner.png`;

    // status = "holding"
    if(status === 'holding') {
        mobileImage = `/resources/images/${promotionCampaign}/banner-holding-mobile.png`;
        desktopImage = `/resources/images/${promotionCampaign}/banner-holding.png`;
    } else if(status === 'end') {
        mobileImage = `/resources/images/${promotionCampaign}/banner-end-mobile.png`;
        desktopImage = `/resources/images/${promotionCampaign}/banner-end.png`;
    }

    // specific page
    if(status === 'main' && promotionCampaign === 'netto' && window.location.pathname === GLOBAL_CONFIG.Route.thankyou) {
        mobileImage = `/resources/images/${promotionCampaign}/banner-thankyou-mobile.png`;
        desktopImage = `/resources/images/${promotionCampaign}/banner-thankyou.png`;
    }

    // specific page
    if(status === 'main' && promotionCampaign === 'edeka' && window.location.pathname === GLOBAL_CONFIG.Route.thankyou) {
        mobileImage = `/resources/images/${promotionCampaign}/banner-thankyou-mobile.png`;
        desktopImage = `/resources/images/${promotionCampaign}/banner-thankyou.png`;
    }
    // specific page
    if(status === 'main' && promotionCampaign === 'budni' && window.location.pathname === GLOBAL_CONFIG.Route.thankyou) {
        mobileImage = `/resources/images/${promotionCampaign}/banner-thankyou-mobile.png`;
        desktopImage = `/resources/images/${promotionCampaign}/banner-thankyou.png`;
    }
    // specific page
    if(status === 'main' && promotionCampaign === 'kaufland' && window.location.pathname === GLOBAL_CONFIG.Route.thankyou) {
        mobileImage = `/resources/images/${promotionCampaign}/banner-thankyou-mobile.png`;
        desktopImage = `/resources/images/${promotionCampaign}/banner-thankyou.png`;
    }

    return (
        <Aux>
            {/* <div className="banner__container separator__curve--banner-white">/ */}
                <div className="image__container">
                    <div className="image__img">
                        <picture>
                            <source srcSet={desktopImage} media="(min-width: 1024px)" />
                            <source srcSet={desktopImage} media="(min-width: 481px)" />
                            <source srcSet={mobileImage} media="(min-width: 200px)" />
                            <img src={desktopImage} alt="Milka Christmas" />
                        </picture>
                    </div>
                </div>
            {/* </div> */}
        </Aux>
    );

}

export default banner;