import React from 'react';
import parse from 'html-react-parser';

/* 
    Title content on all pages - to display the first part of all pages
    This gives uniformity in all pages in terms of CSS and HTML codes
*/



const TitleContent = (props) => {
  let RenderHeading = props.heading ? <h1>{props.heading}</h1> : null;
  let RenderSubheading = props.subheading ? <h2>{props.subheading}</h2> : null;
  let RenderParaText = props.paragraph ? <p>{props.paragraph}</p> : null;
  let RenderParaText2 = props.paragraph2 ? <p>{props.paragraph2}</p> : null;

  // Render bullet points if bullet prop exists and is an array
  let RenderBulletPoints = Array.isArray(props.bullet) ? (
    <ul className='Bulletpoints'>
      {props.bullet.map((point, index) => (
        <li key={index}>{point}</li>
      ))}
    </ul>
  ) : null;

  return (
    <div className="s-content-title">
      {RenderHeading}
      {RenderSubheading}
      {RenderParaText}
      {RenderBulletPoints}
      {RenderParaText2}
        
    </div>
  );
};

export default TitleContent;