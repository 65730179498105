import React from 'react';
import appGoogleTracking from '../../../modules/googletracking';

/*
    Google tagging plan added on links using : appGoogleTracking.processEventCTA
*/

const footersocialV1 = () => (
    <div className="footer__social">
        <a className="js-event-cta"
            href="https://www.instagram.com/milka_deutschland/"
            target="_blank" 
            data-event="go_to_social_media"
            data-category="Footer"
            data-action="Go to Social Media"
            data-label="Instagram"
            rel="noopener noreferrer" 
               onClick={appGoogleTracking.processEventCTA}>
            <span className="icon-instagram"></span>
           <span  className='icon_text'> INSTAGRAM</span>
        </a>
        <a className="js-event-cta"
         href="https://www.facebook.com/Milka/?brand_redir=122033402999"
          target="_blank" 
          data-event="go_to_social_media"
           data-category="Footer" 
           data-action="Go to Social Media" 
           data-label="Facebook" 
           rel="noopener noreferrer" onClick={appGoogleTracking.processEventCTA}>
            <span className="icon-facebook"></span>
            <span className='icon_text'> FACEBOOK</span>
        </a>
        <a className="js-event-cta"
         href="https://www.youtube.com/user/Milka" 
         target="_blank" 
        rel="noopener noreferrer"
        data-event="go_to_social_media"
        data-category="Footer"
        data-action="Go to Social Media"
        data-label="Youtube"
              
               onClick={appGoogleTracking.processEventCTA}>
            <span className="icon-youtube"><span className="icon-boundary"></span><span className="icon-play"></span></span>
            <span className='icon_text'> YOUTUBE</span>
        </a>
        <a className="js-event-cta"
         href="https://www.pinterest.de/milka_dach/"
            target="_blank"
            rel="noopener noreferrer"
            data-event="go_to_social_media"
            data-category="Footer"
            data-action="Go to Social Media"
            data-label="Pinterest"
            onClick={appGoogleTracking.processEventCTA}>
            <span className="icon-pinterest"></span>
            <span className='icon_text'> PINTEREST</span>
        </a>
       
        <a
      className="js-event-cta"
      href="https://www.tiktok.com/@milka_de_at"
      target="_blank"
      rel="noopener noreferrer"
      data-event="go_to_social_media"
      data-category="Footer"
      data-action="Go to Social Media"
      data-label="Tiktok"
      onClick={appGoogleTracking.processEventCTA}
    >
      <span className="icon-tiktok">
        <img src="/resources/icons/Vector.png" alt="Milka Tiktok" />
      </span>
      <span className="icon_text"> TIKTOK</span>
    </a>
    </div>
);

export default footersocialV1;