import axios from "axios";
import GLOBAL_CONFIG from "../config/config";

function campaignServices() {
    const _BASE_URL = "/api";
    const _NEWSLETTER = '/api/' + GLOBAL_CONFIG.Settings.defaultSite + '/NewsletterSub';

    const servicesUrl = () => {
        var hostName = window.location.hostname,
            isProd = hostName.toLowerCase().indexOf('milka.de') > -1 ? true : false,
            isHiddenProd = hostName.toLowerCase().indexOf('run.app') > -1 ? true : false,
            isStage = hostName.toLowerCase().indexOf('mdlzapps.cloud') > -1 ? true : false;

        if (isProd) {
            return 'https://api.weihnachtsgewinnspiel.milka.de';
        }
        if (isStage) {
            return 'https://api-weihnachtsgewinnspiel-milka-de.mdlzapps.cloud';
        }
        if (isHiddenProd) {
            return 'https://proximitybbdo-xmas-milka-de-api-v25ef3pzhq-ey.a.run.app';
        }
          return 'https://api-weihnachtsgewinnspiel-milka-de.mdlzapps.cloud'
    }

    const participation = ({ data }) => {
        const campaignUrl = servicesUrl();
        return axios({
            method: "POST",
            url: campaignUrl + `/Xmas/SubmitParticipation`,
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    }

    const status = () => {
        const campaignUrl = servicesUrl();
        return axios({
            method: "GET",
            url: campaignUrl + `/CampaignStatusXmas` 
        })
    }

    const newsletterSubscribe = ({ retailer, data }) => {
        return axios({
            method: "POST",
            url: `${_BASE_URL}/${retailer === "muller" ? "mueller" : retailer}/newsletterSubscribe`,
            data: data
        });
    }
    const newsletterSub = ({ retailer, data }) => {
        const campaignUrl = servicesUrl({ retailer });
        return axios({
            method: 'POST',
            url: campaignUrl + `/` + retailer.replace(/^./, retailer[0].toUpperCase()) + `/NewsletterReg`,
            data: data,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
    }

    const supportingProfile = (_data) => {
        return axios({
            method: 'post',
            url: `https://milka-supporting.azurewebsites.net/supporting/crm/profile`,
            data: _data
        })
    }

    const cashBack = ({ retailer, data }) => {
        const campaignUrl = servicesUrl({ retailer });
        return axios({
            method: "POST",
            url: campaignUrl + `/` + retailer.replace(/^./, retailer[0].toUpperCase()) + `/SubmitBankDetails`,
            data: data,
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        })
    }

    return {
        Status: status,
        Participate: participation,
        NewsletterSubscribe: newsletterSub,
        SupportingProfile: supportingProfile,
        CashBack: cashBack
    }
}

const CampaignServices = campaignServices();

export default CampaignServices;