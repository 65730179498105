import React from "react";
import GLOBAL_CONFIG from "../../../config/config";
import Aux from "../../../hoc/Auxiliare";
import parse from "html-react-parser";
import { NavLink } from "react-router-dom";
import { timeStamp } from "../../../modules/helpers";
import appGoogleTracking from "../../../modules/googletracking";
import { scrollToReceipt } from "../../../modules/helpers";
/*
  Contains the step that a user must do to participate in the campaign
*/

const StepContent = (props) => {
  let { title, toScroll } = props,
    contenStep = null,
    promotionCampaign = window.PROMOTION_CAMPAIGN;
  const { t } = props;

  if (promotionCampaign === "muller") {
    if (
      window.location.pathname.toLowerCase() ===
      GLOBAL_CONFIG.Route.home.toLowerCase()
    ) {
      contenStep = (
        <Aux>
          <h2>{title}</h2>

          <div className="s-content-steps__step first">
            <picture>
              <img
                src="/resources/images/muller/steps-01.png"
                className="s-content-steps__image"
                alt="Participation Steps"
              />
            </picture>
            <div className="s-content-steps__copy first">
              <p>
                2 Milka Produkte bei <strong>Müller</strong>
                <br /> in einem Kaufakt kaufen
              </p>
            </div>
          </div>

          <div className="s-content-steps__step second">
            <picture>
              <img
                src="/resources/images/muller/steps-02.png"
                className="s-content-steps__image"
                alt="Participation Steps"
              />
            </picture>
            <div className="s-content-steps__copy second">
              <p>
                <strong>Kaufbeleg</strong>
                <br /> bis zum 16.04.2022{" "}
                <NavLink
                  to={GLOBAL_CONFIG.Route.participation}
                  exact
                  data-event="button-click"
                  data-category="Click Action"
                  data-action="Participate"
                  data-label={timeStamp()}
                  onClick={appGoogleTracking.processEventCTANavLink}
                >
                  <>hier</>
                </NavLink>{" "}
                hochladen
              </p>
            </div>
          </div>

          <div className="s-content-steps__step third">
            <picture>
              <img
                src="/resources/images/muller/steps-03.png"
                className="s-content-steps__image"
                alt="Participation Steps"
              />
            </picture>
            <div className="s-content-steps__copy third">
              <p>
                Täglich einen CEWE Gutschein <br /> für eine personalisierte XL{" "}
                <strong>Foto-Postkarte inkl. Versand</strong> gewinnen
              </p>
            </div>
          </div>
        </Aux>
      );
    } else {
      contenStep = (
        <Aux>
          <h2>{title}</h2>

          <div className="s-content-steps__step first">
            <picture>
              <img
                src="/resources/images/muller/steps-01.png"
                className="s-content-steps__image"
                alt="Participation Steps"
              />
            </picture>
            <div className="s-content-steps__copy first">
              <p>
                2 Milka Produkte bei <strong>Müller</strong>
                <br /> in einem Kaufakt kaufen
              </p>
            </div>
          </div>

          <div className="s-content-steps__step second">
            <picture>
              <img
                src="/resources/images/muller/steps-02.png"
                className="s-content-steps__image"
                alt="Participation Steps"
              />
            </picture>
            <div className="s-content-steps__copy second">
              <p>
                <strong>Kaufbeleg</strong>
                <br /> bis zum 16.04.2022{" "}
                <NavLink
                  to={GLOBAL_CONFIG.Route.participation}
                  exact
                  data-event="button-click"
                  data-category="Click Action"
                  data-action="Participate"
                  data-label={timeStamp()}
                  onClick={appGoogleTracking.processEventCTANavLink}
                >
                  <>hier</>
                </NavLink>{" "}
                hochladen
              </p>
            </div>
          </div>

          <div className="s-content-steps__step third">
            <picture>
              <img
                src="/resources/images/muller/steps-03.png"
                className="s-content-steps__image"
                alt="Participation Steps"
              />
            </picture>
            <div className="s-content-steps__copy third">
              <p>
                Täglich <strong>einen Gutschein</strong> von CEWE für eine
                persönliche <strong>Foto-Postkarte inkl. Versand</strong>{" "}
                gewinnen
              </p>
            </div>
          </div>
        </Aux>
      );
    }
  } else if (promotionCampaign === "rewe") {
    if (
      window.location.pathname.toLowerCase() ===
      GLOBAL_CONFIG.Route.home.toLowerCase()
    ) {
      contenStep = (
        <Aux>
          <h2>{title}</h2>

          <div className="s-content-steps__step first">
            <picture>
              <source
                srcSet="/resources/images/rewe/steps-01.png"
                media="(min-width: 1024px)"
              />
              <source
                srcSet="/resources/images/rewe/steps-01.png"
                media="(min-width: 481px)"
              />
              <source
                srcSet="/resources/images/rewe/steps-01.png"
                media="(min-width: 200px)"
              />
              <img
                src="/resources/images/rewe/steps-01.png"
                className="s-content-steps__image"
                alt="Participation Steps"
              />
            </picture>
            <div className="s-content-steps__copy first">
              <p>
                3x Milka Produkte bei <strong>REWE</strong> in einem Kaufakt
                kaufen
              </p>
            </div>
          </div>

          <div className="s-content-steps__step second">
            <picture>
              <source
                srcSet="/resources/images/rewe/steps-02.png"
                media="(min-width: 1024px)"
              />
              <source
                srcSet="/resources/images/rewe/steps-02.png"
                media="(min-width: 481px)"
              />
              <source
                srcSet="/resources/images/rewe/steps-02.png"
                media="(min-width: 200px)"
              />
              <img
                src="/resources/images/rewe/steps-02.png"
                className="s-content-steps__image"
                alt="Participation Steps"
              />
            </picture>
            <div className="s-content-steps__copy second">
              <p>
                <strong>Kaufbeleg</strong>
                <br /> bis zum 16.04.2022{" "}
                <NavLink
                  to={GLOBAL_CONFIG.Route.participation}
                  exact
                  data-event="button-click"
                  data-category="Click Action"
                  data-action="Participate"
                  data-label={timeStamp()}
                  onClick={appGoogleTracking.processEventCTANavLink}
                >
                  <>hier</>
                </NavLink>{" "}
                hochgeladen
              </p>
            </div>
          </div>

          <div className="s-content-steps__step third">
            <picture>
              <source
                srcSet="/resources/images/rewe/steps-03-mobile.png"
                media="(max-width: 1229px)"
              />
              <img
                src="/resources/images/rewe/steps-03.png"
                className="s-content-steps__image"
                alt="Participation Steps"
              />
            </picture>
            <div className="s-content-steps__copy third">
              <p>
                Wöchentlich 1 von 100 <strong>REWE Gutscheinen</strong> <br />{" "}
                im Wert von 50€ gewinnen
              </p>
            </div>
          </div>

          <div className="s-content-steps__step fourth">
            <picture>
              <source
                srcSet="/resources/images/rewe/steps-04.png"
                media="(min-width: 1024px)"
              />
              <source
                srcSet="/resources/images/rewe/steps-04.png"
                media="(min-width: 481px)"
              />
              <source
                srcSet="/resources/images/rewe/steps-04.png"
                media="(min-width: 200px)"
              />
              <img
                src="/resources/images/rewe/steps-04.png"
                className="s-content-steps__image"
                alt="Participation Steps"
              />
            </picture>
            <div className="s-content-steps__copy fourth">
              <p>
                <strong>Doppelt freuen:</strong> <br /> Pro Gewinner spendet
                Milka 20€ an Tafel Deutschland e.V.
              </p>
            </div>
          </div>
        </Aux>
      );
    } else {
      contenStep = (
        <Aux>
          <h2>{title}</h2>

          <div className="s-content-steps__step first">
            <picture>
              <source
                srcSet="/resources/images/rewe/steps-01.png"
                media="(max-width: 480px)"
              />
              <img
                src="/resources/images/rewe/faq-steps-01.png"
                className="s-content-steps__image"
                alt="Participation Steps"
              />
            </picture>
            <div className="s-content-steps__copy first">
              <p>
                3x Milka Produkte bei <strong>REWE</strong> in einem Kaufakt
                kaufen
              </p>
            </div>
          </div>

          <div className="s-content-steps__step second">
            <picture>
              <source
                srcSet="/resources/images/rewe/steps-02.png"
                media="(max-width: 480px)"
              />
              <img
                src="/resources/images/rewe/faq-steps-02.png"
                className="s-content-steps__image"
                alt="Participation Steps"
              />
            </picture>
            <div className="s-content-steps__copy second">
              {/* <picture>
                                <source srcSet="/resources/images/rewe/kassenbonImage.png" media="(min-width: 1024px)" />
                                <source srcSet="/resources/images/rewe/kassenbonImage.png" media="(min-width: 481px)" />
                                <source srcSet="/resources/images/rewe/kassenbonImage.png" media="(min-width: 200px)" />
                                <img src="/resources/images/rewe/kassenbonImage.png" className="s-content-steps__kassenbon" alt="Participation Kassenbon" />
                            </picture> */}
              <p>
                <strong>Kaufbeleg</strong>
                <br /> bis zum 16.04.2022{" "}
                <NavLink
                  to={GLOBAL_CONFIG.Route.participation}
                  exact
                  data-event="button-click"
                  data-category="Click Action"
                  data-action="Participate"
                  data-label={timeStamp()}
                  onClick={appGoogleTracking.processEventCTANavLink}
                >
                  <>hier</>
                </NavLink>{" "}
                hochladen
              </p>
            </div>
          </div>

          <div className="s-content-steps__step third">
            <picture>
              <source
                srcSet="/resources/images/rewe/steps-03-mobile.png"
                media="(max-width: 480px)"
              />
              <img
                src="/resources/images/rewe/faq-steps-03.png"
                className="s-content-steps__image"
                alt="Participation Steps"
              />
            </picture>
            <div className="s-content-steps__copy third">
              <p>
                Wöchentlich 1 von 100 <strong>REWE Gutscheinen</strong> <br />{" "}
                im Wert von 50€ gewinnen
              </p>
            </div>
          </div>

          <div className="s-content-steps__step fourth">
            <picture>
              <source
                srcSet="/resources/images/rewe/steps-04.png"
                media="(max-width: 480px)"
              />
              <img
                src="/resources/images/rewe/faq-steps-04.png"
                className="s-content-steps__image"
                alt="Participation Steps"
              />
            </picture>
            <div className="s-content-steps__copy fourth">
              <p>
                <strong>Doppelt freuen:</strong> <br /> Pro Gewinner spendet
                Milka 20€ an Tafel Deutschland e.V.
              </p>
            </div>
          </div>
        </Aux>
      );
    }
  } else if (promotionCampaign === "kaufland") {
    if (window.location.pathname === GLOBAL_CONFIG.Route.home) {
      contenStep = (
        <Aux>
          <div className="s-content-steps">
            <h2>{title}</h2>
            <div className="s-content-steps__item kaufland">
              <div className="s-content-steps__item-image">
                <img
                  src="/resources/images/kaufland/step-1.png"
                  width="217"
                  height="195"
                  alt="Step 1"
                />
              </div>
              <p className="first">
                5 x Milka Produkte bei Kaufland in einem Kaufvorgang kaufen
              </p>
            </div>
            <div className="s-content-steps__item kaufland">
              <div className="s-content-steps__item-image">
                <img
                  src="/resources/images/kaufland/step-2.png"
                  width="240"
                  height="230"
                  alt="Step 2"
                />
              </div>
              <p>
                Kaufbeleg{" "}
                <NavLink
                  to={GLOBAL_CONFIG.Route.participation}
                  exact
                  data-event="upload_receipt link"
                  data-category="Start Page"
                  data-action="Click on Link"
                  data-label="GAME_SP_PART"
                  onClick={appGoogleTracking.processEventCTANavLink}
                >
                  <>hier</>
                </NavLink>{" "}
                bis zum 24.12.2023 hochladen
              </p>
            </div>
            <div className="s-content-steps__item kaufland">
              <div className="s-content-steps__item-image">
                <img
                  src="/resources/images/kaufland/step-3.png"
                  width="217"
                  height="195"
                  alt="Step 3"
                />
              </div>
              <p>
                Täglich 1 von 10 Kaufland-Gutscheinen im Wert von maximal 200 €
                gewinnen{" "}
              </p>
            </div>
          </div>
        </Aux>
      );
    } else if (window.location.pathname === GLOBAL_CONFIG.Route.participation) {
      contenStep = (
        <Aux>
          <div className="s-content-steps">
            <h2>{title}</h2>
            <div className="s-content-steps__item kaufland">
              <div className="s-content-steps__item-image">
                <img
                  src="/resources/images/kaufland/step-1.png"
                  width="239"
                  height="214"
                  alt="Step 1"
                />
              </div>
              <p className="first">
                5 x Milka Produkte bei Kaufland in einem Kaufvorgang kaufen
              </p>
            </div>
            <div className="s-content-steps__item kaufland">
              <div className="s-content-steps__item-image">
                <img
                  src="/resources/images/kaufland/step-2.png"
                  width="240"
                  height="230"
                  alt="Step 2"
                />
              </div>
              <p>
                Kaufbeleg{" "}
                <a
                  className="to-scroll"
                  data-event="upload_receipt_link"
                  data-category="Take Part Page"
                  data-action="Click on Link"
                  data-label="GAME_SP_PART"
                  onClick={toScroll}
                >
                  hier
                </a>{" "}
                bis zum 24.12.2023 hochladen
              </p>
            </div>
            <div className="s-content-steps__item kaufland">
              <div className="s-content-steps__item-image">
                <img
                  src="/resources/images/kaufland/step-3.png"
                  width="217"
                  height="195"
                  alt="Step 2"
                />
              </div>
              <p>
                Täglich 1 von 10 Kaufland-Gutscheinen im Wert von maximal 200 €
                gewinnen
              </p>
            </div>
          </div>
        </Aux>
      );
    } else if (window.location.pathname === GLOBAL_CONFIG.Route.faq) {
      contenStep = (
        <Aux>
          <div className="s-content-steps">
            <h2>{title}</h2>
            <div className="s-content-steps__item kaufland">
              <div className="s-content-steps__item-image">
                <img
                  src="/resources/images/kaufland/step-1.png"
                  width="239"
                  height="214"
                  alt="Step 1"
                />
              </div>
              <p className="first">
                5 x Milka Produkte bei Kaufland in einem Kaufvorgang kaufen
              </p>
            </div>
            <div className="s-content-steps__item kaufland">
              <div className="s-content-steps__item-image">
                <img
                  src="/resources/images/kaufland/step-2.png"
                  width="240"
                  height="230"
                  alt="Step 2"
                />
              </div>
              <p>
                Kaufbeleg{" "}
                <NavLink
                  to={GLOBAL_CONFIG.Route.home}
                  exact
                  data-event="upload_receipt link"
                  data-category="FAQ Page"
                  data-action="Click on Link"
                  data-label="GAME_FAQ_PART"
                  onClick={appGoogleTracking.processEventCTANavLink}
                >
                  <>hier</>
                </NavLink>{" "}
                bis zum 24.12.2023 hochladen
              </p>
            </div>
            <div className="s-content-steps__item kaufland">
              <div className="s-content-steps__item-image">
                <img
                  src="/resources/images/kaufland/step-3.png"
                  width="217"
                  height="195"
                  alt="Step 2"
                />
              </div>
              <p>
                Täglich 1 von 10 Kaufland-Gutscheinen im Wert von maximal 200 €
                gewinnen
              </p>
            </div>
          </div>
        </Aux>
      );
    }
  } else if (promotionCampaign === "xmas") {
    if (window.location.pathname === GLOBAL_CONFIG.Route.home) {
      contenStep = (
        <Aux>
          <div className="container-steps">
            <>
              <h2>{title}</h2>
              <div className="container-steps__steps">
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-1.png" alt="Step 1" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Milka Produkte in <br /> einem Kaufakt im <br /> Wert von 5
                    € kaufen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-2.png" alt="Step 2" />
                  </div>
                  <p className="small s-content-steps__item__text">
                    Kaufbeleg{" "}
                    <NavLink
                      to={`${GLOBAL_CONFIG.Route.participation}`}
                      exact
                      data-target="#frm_participation"
                      data-event="upload_receipt link"
                      data-category="Start Page"
                      data-action="Click on Link"
                      data-label="GAME_SP_PART"
                      onClick={(e) => {
                        appGoogleTracking.processEventCTANavLink(e);
                        setTimeout(() => {
                          const faqSection = document.getElementById('frm_participation');
                          if (faqSection) {
                            faqSection.scrollIntoView({behavior: 'smooth'})
                          }
                        },200)
                      }}
                    >
                      hier
                    </NavLink>{" "}
                    <br /> hochladen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-3.png" alt="Step 2" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Überraschungs-
                    <br />
                    geschenk öffnen und <br /> garantiert gewinnen!
                  </p>
                </div>
              </div>
            </>
            <div className="content-home__container">
              <NavLink
                to={GLOBAL_CONFIG.Route.participation}
                exact
                data-event="join_now"
                data-category="Start Page"
                data-action="Click on Join Now"
                data-label="GAME_SP_PART"
                onClick={(e) => {
                  appGoogleTracking.processEventCTANavLink(e);
                  setTimeout(() => {
                    const faqSection = document.getElementById('frm_participation');
                    if (faqSection) {
                      faqSection.scrollIntoView({behavior: 'smooth'})
                    }
                  },200)
                }}

              >
                <div className="btn__container btn--primary btn--hover">
                  <span className="btn__text">
                    {t("Page.Home.linkParticipation")}
                  </span>
                </div>
              </NavLink>
              <p className="small redirect-terms">
                <NavLink
                  to={GLOBAL_CONFIG.Route.terms}
                  exact
                  data-event="click_conditions"
                  data-category="Start Page"
                  data-action="Click on Conditions of Participation"
                  data-label="ENG_SP_OTHER"
                  onClick={appGoogleTracking.processEventCTANavLink}
                  target="_blank"
                >
                  Teilnahmebedingungen
                </NavLink>{" "}
                der Aktion
              </p>
            </div>
          </div>
        </Aux>
      );
    } else if (window.location.pathname === GLOBAL_CONFIG.Route.participation) {
      contenStep = (
        <Aux>
          <div className="container-steps">
            <>
              <h2>{title}</h2>
              <div className="container-steps__steps">
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-1.png" alt="Step 1" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Milka Produkte in <br /> einem Kaufakt im <br /> Wert von 5
                    € kaufen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-2.png" alt="Step 2" />
                  </div>
                  <p className="small s-content-steps__item__text">
                    Kaufbeleg{" "}
                    <a
                      href="/mitmachen"
                      data-target="#frm_participation"
                      data-event="upload_receipt_link"
                      data-category="Take Part Page"
                      data-action="Click on Link"
                      data-label="GAME_TPP_PART"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the URL from changing
                        appGoogleTracking.processEventCTANavLink(e);
                        document.querySelector(e.target.getAttribute("data-target")).scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      hier
                    </a>{" "}
                    <br /> hochladen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-3.png" alt="Step 2" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Überraschungs-
                    <br />
                    geschenk öffnen und <br /> garantiert gewinnen!
                  </p>
                </div>
              </div>
            </>
            <div className="content-home__container">
               <a
                href="/mitmachen"
                data-target="#frm_participation"
                data-event="join_now"
                data-category="Take Part Page"
                data-action="Click on Join Now"
                data-label="GAME_TPP_PART"
                onClick={(e) => {
                  e.preventDefault();
                  appGoogleTracking.processEventCTANavLink(e);
                  document.querySelector("#frm_participation").scrollIntoView({ behavior: "smooth" });
              }}

              >
                <div className="btn__container btn--primary btn--hover">
                  <span className="btn__text">
                    {t("Page.Home.linkParticipation")}
                  </span>
                </div>
              </a>
              <p className="small redirect-terms">
                <NavLink
                  to={GLOBAL_CONFIG.Route.terms}
                  exact
                  data-event="click_conditions_join_now"
                  data-category="Take Part Page"
                  data-action="Click on Conditions"
                  data-label="ENG_TPP_OTHER"
                  onClick={appGoogleTracking.processEventCTANavLink}
                  target="_blank"
                >
                  Teilnahmebedingungen
                </NavLink>{" "}
                der Aktion
              </p>
            </div>
          </div>
        </Aux>
      );
    } else if (window.location.pathname === GLOBAL_CONFIG.Route.faq) {
      contenStep = (
        <Aux>
          <div className="container-steps">
            <>
              <h2>{title}</h2>
              <div className="container-steps__steps">
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-1.png" alt="Step 1" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Milka Produkte in <br /> einem Kaufakt im <br /> Wert von 5
                    € kaufen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-2.png" alt="Step 2" />
                  </div>
                  <p className="small s-content-steps__item__text">
                    Kaufbeleg{" "}
                    <NavLink
                      to={`${GLOBAL_CONFIG.Route.participation}`}
                      exact
                      data-target="#frm_participation"
                      data-event="upload_receipt_link"
                      data-category="FAQ Page"
                      data-action="Click on Link"
                      data-label="GAME_FAQ_PART"
                      onClick={(e) => {
                        appGoogleTracking.processEventCTANavLink(e);
                        setTimeout(() => {
                          const faqSection = document.getElementById('frm_participation');
                          if (faqSection) {
                            faqSection.scrollIntoView({behavior: 'smooth'})
                          }
                        },200)
                      }}
                    >
                      hier
                    </NavLink>{" "}
                    <br /> hochladen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-3.png" alt="Step 2" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Überraschungs-
                    <br />
                    geschenk öffnen und <br /> garantiert gewinnen!
                  </p>
                </div>
              </div>
            </>
            <div className="content-home__container">
              <NavLink
                to={GLOBAL_CONFIG.Route.participation}
                exact
                data-event="join_now"
                data-category="FAQ Page"
                data-action="Click on Join Now"
                data-label="GAME_FAQ_PART"
                onClick={(e) => {
                  appGoogleTracking.processEventCTANavLink(e);
                  setTimeout(() => {
                    const faqSection = document.getElementById('frm_participation');
                    if (faqSection) {
                      faqSection.scrollIntoView({behavior: 'smooth'})
                    }
                  },200)
                }}
              >
                <div className="btn__container btn--primary btn--hover">
                  <span className="btn__text">
                    {t("Page.Home.linkParticipation")}
                  </span>
                </div>
              </NavLink>
              <p className="small redirect-terms">
                <NavLink
                  to={GLOBAL_CONFIG.Route.terms}
                  exact
                  data-event="click_conditions_join_now"
                  data-category="FAQ Page"
                  data-action="Click on Conditions"
                  data-label="ENG_FAQ_OTHER"
                  onClick={appGoogleTracking.processEventCTANavLink}
                  target="_blank"
                >
                  Teilnahmebedingungen
                </NavLink>{" "}
                der Aktion
              </p>
            </div>
          </div>
        </Aux>
      );
    }
  } else if (promotionCampaign === "edeka") {
    if (window.location.pathname === GLOBAL_CONFIG.Route.home) {
      contenStep = (
        <Aux>
          <div className="container-steps">
            <>
              <h2>{title}</h2>
              <div className="container-steps__steps">
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img
                      src="/resources/images/edeka/step-1.png"
                      alt="Step 1"
                    />
                  </div>
                  <p className="s-content-steps__item__text">
                    Milka Produkte in <br /> einem Kaufakt im <br /> Wert von 5
                    € kaufen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img
                      src="/resources/images/edeka/step-2.png"
                      alt="Step 2"
                    />
                  </div>
                  <p className="small s-content-steps__item__text">
                    Kaufbeleg{" "}
                    <a
                      href="/mitmachen"
                      data-target="#frm_participation"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the URL from changing
                        document.querySelector(e.target.getAttribute("data-target")).scrollIntoView({ behavior: "smooth" });

                      }}
                    >
                      hier
                    </a>{" "}
                    <br /> hochladen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img
                      src="/resources/images/edeka/step-3.png"
                      alt="Step 2"
                    />
                  </div>
                  <p className="s-content-steps__item__text">
                    Überraschungs-
                    <br />
                    geschenk öffnen und <br /> garantiert gewinnen!
                  </p>
                </div>
              </div>
            </>
            <div className="content-home__container">
              <NavLink
                to={GLOBAL_CONFIG.Route.participation}
                exact
                data-event="join_now"
                data-category="Start Page"
                data-action="Click on Join Now"
                data-label="GAME_SP_PART"
                onClick={appGoogleTracking.processEventCTANavLink}
              >
                <div className="btn__container btn--primary btn--hover">
                  <span className="btn__text">
                    {t("Page.Home.linkParticipation")}
                  </span>
                </div>
              </NavLink>
              <p className="small redirect-terms">
                <NavLink
                  to={GLOBAL_CONFIG.Route.terms}
                  exact
                  data-event="conditions_of_participation"
                  data-category="Start Page"
                  data-action="Click on Link"
                  data-label="GAME_SP_OTHER"
                  onClick={appGoogleTracking.processEventCTANavLink}
                  target="_blank"
                >
                  Teilnahmebedingungen
                </NavLink>{" "}
                der Aktion
              </p>
            </div>
          </div>
        </Aux>
      );
    } else if (window.location.pathname === GLOBAL_CONFIG.Route.participation) {
      contenStep = (
        <Aux>
          <div className="container-steps">
            <>
              <h2>{title}</h2>
              <div className="container-steps__steps">
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img
                      src="/resources/images/edeka/step-1.png"
                      alt="Step 1"
                    />
                  </div>
                  <p className="s-content-steps__item__text">
                    Milka Produkte in <br /> einem Kaufakt im <br /> Wert von 5
                    € kaufen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img
                      src="/resources/images/edeka/step-2.png"
                      alt="Step 2"
                    />
                  </div>
                  <p className="small s-content-steps__item__text">
                    Kaufbeleg{" "}
                    <a
                      href="/mitmachen"
                      data-target="#frm_participation"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the URL from changing
                        document.querySelector(e.target.getAttribute("data-target")).scrollIntoView({ behavior: "smooth" });

                      }}
                    >
                      hier
                    </a>{" "}
                    <br /> hochladen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img
                      src="/resources/images/edeka/step-3.png"
                      alt="Step 2"
                    />
                  </div>
                  <p className="s-content-steps__item__text">
                    Überraschungs-
                    <br />
                    geschenk öffnen und <br /> garantiert gewinnen!
                  </p>
                </div>
              </div>
            </>
            <div className="content-home__container">
               <NavLink
                to={GLOBAL_CONFIG.Route.participation}
                exact
                data-event="join_now"
                data-category="Take Part Page"
                data-action="Click on Join Now"
                data-label="GAME_TPP_PART"
                onClick={(e) => {
                    appGoogleTracking.processEventCTANavLink(e);
                    document.querySelector(e.target.getAttribute("data-target")).scrollIntoView({ behavior: "smooth" });
                }}
              >
                <div className="btn__container btn--primary btn--hover">
                  <span className="btn__text">
                    {t("Page.Home.linkParticipation")}
                  </span>
                </div>
              </NavLink>
              <p className="small redirect-terms">
                <NavLink
                  to={GLOBAL_CONFIG.Route.terms}
                  exact
                  data-event="conditions_of_participation"
                  data-category="Take Part Page"
                  data-action="Click on Link"
                  data-label="GAME_TPP_OTHER"
                  onClick={appGoogleTracking.processEventCTANavLink}
                  target="_blank"
                >
                  Teilnahmebedingungen
                </NavLink>{" "}
                der Aktion
              </p>
            </div>
          </div>
        </Aux>
      );
    } else if (window.location.pathname === GLOBAL_CONFIG.Route.faq) {
      contenStep = (
        <Aux>
          <div className="container-steps">
            <>
              <h2>{title}</h2>
              <div className="container-steps__steps">
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img
                      src="/resources/images/edeka/step-1.png"
                      alt="Step 1"
                    />
                  </div>
                  <p className="s-content-steps__item__text">
                    Milka Produkte in <br /> einem Kaufakt im <br /> Wert von 5
                    € kaufen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img
                      src="/resources/images/edeka/step-2.png"
                      alt="Step 2"
                    />
                  </div>
                  <p className="small s-content-steps__item__text">
                    Kaufbeleg{" "}
                    <a
                      href="/mitmachen"
                      data-target="#frm_participation"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the URL from changing
                        document.querySelector(e.target.getAttribute("data-target")).scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      hier
                    </a>{" "}
                    <br /> hochladen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img
                      src="/resources/images/edeka/step-3.png"
                      alt="Step 2"
                    />
                  </div>
                  <p className="s-content-steps__item__text">
                    Überraschungs-
                    <br />
                    geschenk öffnen und <br /> garantiert gewinnen!
                  </p>
                </div>
              </div>
            </>
            <div className="content-home__container">
              <NavLink
                to={GLOBAL_CONFIG.Route.participation}
                exact
                data-event="join_now"
                data-category="FAQ Page"
                data-action="Click on Join Now"
                data-label="GAME_FAQ_PART"
                onClick={appGoogleTracking.processEventCTANavLink}
              >
                <div className="btn__container btn--primary btn--hover">
                  <span className="btn__text">
                    {t("Page.Home.linkParticipation")}
                  </span>
                </div>
              </NavLink>
              <p className="small redirect-terms">
                <NavLink
                  to={GLOBAL_CONFIG.Route.terms}
                  exact
                  data-event="conditions_of_participation"
                  data-category="FAQ Page"
                  data-action="Click on Link"
                  data-label="ENG_FAQ_OTHER"
                  onClick={appGoogleTracking.processEventCTANavLink}
                  target="_blank"
                >
                  Teilnahmebedingungen
                </NavLink>{" "}
                der Aktion
              </p>
            </div>
          </div>
        </Aux>
      );
    }
  } else if (promotionCampaign === "netto") {
    if (window.location.pathname === GLOBAL_CONFIG.Route.home) {
      contenStep = (
        <Aux>
          <div className="container-steps">
            <>
              <h2>{title}</h2>
              <div className="container-steps__steps">
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-1.png" alt="Step 1" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Milka Produkte im Wert von 5 € beim Netto Marken-Discount
                    kaufen
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-2.png" alt="Step 2" />
                  </div>
                  <p className="small s-content-steps__item__text">
                    Kaufbeleg{" "}
                    <a
                      href="/mitmachen"
                      data-target="#frm_participation"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the URL from changing
                        document
                          .querySelector(e.target.getAttribute("data-target"))
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      hier
                    </a>{" "}
                    <br /> hochladen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-3.png" alt="Step 2" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Überraschungs-
                    <br />
                    geschenk öffnen und <br /> garantiert gewinnen!
                  </p>
                </div>
              </div>
            </>
            <div className="content-home__container">
              <NavLink
                to={GLOBAL_CONFIG.Route.participation}
                exact
                data-event="join_now"
                data-category="Start Page"
                data-action="Click on Join Now"
                data-label="GAME_SP_PART"
                onClick={appGoogleTracking.processEventCTANavLink}
              >
                <div className="btn__container btn--primary btn--hover">
                  <span className="btn__text">
                    {t("Page.Home.linkParticipation")}
                  </span>
                </div>
              </NavLink>
              <p className="small redirect-terms">
                <NavLink
                  to={GLOBAL_CONFIG.Route.terms}
                  exact
                  data-event="conditions_of_participation"
                  data-category="Start Page"
                  data-action="Click on Link"
                  data-label="GAME_SP_OTHER"
                  onClick={appGoogleTracking.processEventCTANavLink}
                >
                  Teilnahmebedingungen
                </NavLink>{" "}
                der Aktion
              </p>
            </div>
          </div>
        </Aux>
      );
    } else if (window.location.pathname === GLOBAL_CONFIG.Route.participation) {
      contenStep = (
        <Aux>
          <div className="container-steps">
            <>
              <h2>{title}</h2>
              <div className="container-steps__steps">
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-1.png" alt="Step 1" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Milka Produkte im Wert von 5 € beim Netto Marken-Discount
                    kaufen
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-2.png" alt="Step 2" />
                  </div>
                  <p className="small s-content-steps__item__text">
                    Kaufbeleg{" "}
                    <a
                      href="/mitmachen"
                      data-target="#frm_participation"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the URL from changing
                        document.querySelector(e.target.getAttribute("data-target")).scrollIntoView({ behavior: "smooth" });                        
                      }}
                    >
                      hier
                    </a>{" "}
                    <br /> hochladen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-3.png" alt="Step 2" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Überraschungs-
                    <br />
                    geschenk öffnen und <br /> garantiert gewinnen!
                  </p>
                </div>
              </div>
            </>
            <div className="content-home__container">
               <NavLink
                to={GLOBAL_CONFIG.Route.participation}
                exact
                data-event="join_now"
                data-category="Start Page"
                data-action="Click on Join Now"
                data-label="GAME_SP_PART"
                onClick={(e) => {
                  appGoogleTracking.processEventCTANavLink(e);
                  
                  // Set scroll position based on device type
                  let scrollPosition;
                  
                  if (window.innerWidth <= 767) { // Mobile
                      scrollPosition = 3000; // Scroll position for mobile
                  } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) { // Tablet
                      scrollPosition = 2800; // Scroll position for tablet
                  } else { // Desktop
                      scrollPosition = 2900; // You can adjust this as needed for desktops
                  }

                  setTimeout(() => {
                      window.scrollTo({
                          top: scrollPosition,
                          behavior: "smooth",
                      });
                  }, 200);
              }}

              >
                <div className="btn__container btn--primary btn--hover">
                  <span className="btn__text">
                    {t("Page.Home.linkParticipation")}
                  </span>
                </div>
              </NavLink>
              <p className="small redirect-terms">
                <NavLink
                  to={GLOBAL_CONFIG.Route.terms}
                  exact
                  data-event="conditions_of_participation"
                  data-category="Take Part Page"
                  data-action="Click on Link"
                  data-label="GAME_TPP_OTHER"
                  onClick={appGoogleTracking.processEventCTANavLink}
                >
                  Teilnahmebedingungen
                </NavLink>{" "}
                der Aktion
              </p>
            </div>
          </div>
        </Aux>
      );
    } else if (window.location.pathname === GLOBAL_CONFIG.Route.faq) {
      contenStep = (
        <Aux>
          <div className="container-steps">
            <>
              <h2>{title}</h2>
              <div className="container-steps__steps">
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-1.png" alt="Step 1" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Milka Produkte im Wert von 5 € beim Netto Marken-Discount
                    kaufen
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-2.png" alt="Step 2" />
                  </div>
                  <p className="small s-content-steps__item__text">
                    Kaufbeleg{" "}
                    <a
                      href="/mitmachen"
                      data-target="#frm_participation"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the URL from changing
                        document.querySelector(e.target.getAttribute("data-target")).scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      hier
                    </a>{" "}
                    <br /> hochladen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-3.png" alt="Step 2" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Überraschungs-
                    <br />
                    geschenk öffnen und <br /> garantiert gewinnen!
                  </p>
                </div>
              </div>
            </>
            <div className="content-home__container">
              <NavLink
                to={GLOBAL_CONFIG.Route.participation}
                exact
                data-event="join_now"
                data-category="FAQ Page"
                data-action="Click on Join Now"
                data-label="GAME_FAQ_PART"
                onClick={appGoogleTracking.processEventCTANavLink}
              >
                <div className="btn__container btn--primary btn--hover">
                  <span className="btn__text">
                    {t("Page.Home.linkParticipation")}
                  </span>
                </div>
              </NavLink>
              <p className="small redirect-terms">
                <NavLink
                  to={GLOBAL_CONFIG.Route.terms}
                  exact
                  data-event="conditions_of_participation"
                  data-category="FAQ Page"
                  data-action="Click on Link"
                  data-label="ENG_FAQ_OTHER"
                  onClick={appGoogleTracking.processEventCTANavLink}
                >
                  Teilnahmebedingungen
                </NavLink>{" "}
                der Aktion
              </p>
            </div>
          </div>
        </Aux>
      );
    }
  } else if (promotionCampaign === "budni") {
    if (window.location.pathname === GLOBAL_CONFIG.Route.home) {
      contenStep = (
        <Aux>
          <div className="container-steps">
            <>
              <h2>{title}</h2>
              <div className="container-steps__steps">
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-1.png" alt="Step 1" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Milka Produkte im Wert von 5 € bei Budni kaufen
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-2.png" alt="Step 2" />
                  </div>
                  <p className="small s-content-steps__item__text">
                    Kaufbeleg{" "}
                    <a
                      href="/mitmachen"
                      data-target="#frm_participation"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the URL from changing
                        document.querySelector(e.target.getAttribute("data-target")).scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      hier
                    </a>{" "}
                    <br /> hochladen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-3.png" alt="Step 2" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Überraschungs-
                    <br />
                    geschenk öffnen und <br /> garantiert gewinnen!
                  </p>
                </div>
              </div>
            </>
            <div className="content-home__container">
              <NavLink
                to={GLOBAL_CONFIG.Route.participation}
                exact
                data-event="join_now"
                data-category="Start Page"
                data-action="Click in Join Now"
                data-label="GAME_SP_PART"
                onClick={appGoogleTracking.processEventCTANavLink}
              >
                <div className="btn__container btn--primary btn--hover">
                  <span className="btn__text">
                    {t("Page.Home.linkParticipation")}
                  </span>
                </div>
              </NavLink>
              <p className="small redirect-terms">
                <NavLink
                  to={GLOBAL_CONFIG.Route.terms}
                  exact
                  data-event="conditions_of_participation"
                  data-category="Start Page"
                  data-action="Click on Link"
                  data-label="GAME_SP_OTHER"
                  onClick={appGoogleTracking.processEventCTANavLink}
                  target="_blank"
                >
                  Teilnahmebedingungen
                </NavLink>{" "}
                der Aktion
              </p>
            </div>
          </div>
        </Aux>
      );
    } else if (window.location.pathname === GLOBAL_CONFIG.Route.participation) {
      contenStep = (
        <Aux>
          <div className="container-steps">
            <>
              <h2>{title}</h2>
              <div className="container-steps__steps">
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-1.png" alt="Step 1" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Milka Produkte im Wert von 5 € bei Budni kaufen
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-2.png" alt="Step 2" />
                  </div>
                  <p className="small s-content-steps__item__text">
                    Kaufbeleg{" "}
                    <a
                      href="/mitmachen"
                      data-target="#frm_participation"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the URL from changing
                        document.querySelector(e.target.getAttribute("data-target")).scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      hier
                    </a>{" "}
                    <br /> hochladen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-3.png" alt="Step 2" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Überraschungs-
                    <br />
                    geschenk öffnen und <br /> garantiert gewinnen!
                  </p>
                </div>
              </div>
            </>
            <div className="content-home__container">
               <NavLink
                to={GLOBAL_CONFIG.Route.participation}
                exact
                data-event="join_now"
                data-category="Start Page"
                data-action="Click on Join Now"
                data-label="GAME_SP_PART"
                onClick={(e) => {
                  appGoogleTracking.processEventCTANavLink(e);
                  
                  // Set scroll position based on device type
                  let scrollPosition;
                  
                  if (window.innerWidth <= 767) { // Mobile
                      scrollPosition = 3000; // Scroll position for mobile
                  } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) { // Tablet
                      scrollPosition = 2800; // Scroll position for tablet
                  } else { // Desktop
                      scrollPosition = 2900; // You can adjust this as needed for desktops
                  }

                  setTimeout(() => {
                      window.scrollTo({
                          top: scrollPosition,
                          behavior: "smooth",
                      });
                  }, 200);
              }}
              >
                <div className="btn__container btn--primary btn--hover">
                  <span className="btn__text">
                    {t("Page.Home.linkParticipation")}
                  </span>
                </div>
              </NavLink>
              <p className="small redirect-terms">
                <NavLink
                  to={GLOBAL_CONFIG.Route.terms}
                  exact
                  data-event="conditions_of_participation"
                  data-category="Take Part Page"
                  data-action="Click on Link"
                  data-label="GAME_TPP_OTHER"
                  onClick={appGoogleTracking.processEventCTANavLink}
                  target="_blank"
                >
                  Teilnahmebedingungen
                </NavLink>{" "}
                der Aktion
              </p>
            </div>
          </div>
        </Aux>
      );
    } else if (window.location.pathname === GLOBAL_CONFIG.Route.faq) {
      contenStep = (
        <Aux>
          <div className="container-steps">
            <>
              <h2>{title}</h2>
              <div className="container-steps__steps">
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-1.png" alt="Step 1" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Milka Produkte im Wert von 5 € bei Budni kaufen
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-2.png" alt="Step 2" />
                  </div>
                  <p className="small s-content-steps__item__text">
                    Kaufbeleg{" "}
                    <a
                      href="/mitmachen"
                      data-target="#frm_participation"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the URL from changing
                        document.querySelector(e.target.getAttribute("data-target")).scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      hier
                    </a>{" "}
                    <br /> hochladen.
                  </p>
                </div>
                <div className="s-content-steps__item">
                  <div className="s-content-steps__item-image">
                    <img src="/resources/images/xmas/step-3.png" alt="Step 2" />
                  </div>
                  <p className="s-content-steps__item__text">
                    Überraschungs-
                    <br />
                    geschenk öffnen und <br /> garantiert gewinnen!
                  </p>
                </div>
              </div>
            </>
            <div className="content-home__container">
              <NavLink
                to={GLOBAL_CONFIG.Route.participation}
                exact
                data-event="join_now"
                data-category="FAQ Page"
                data-action="Click on Join Now"
                data-label="GAME_FAQ_PART"
                onClick={appGoogleTracking.processEventCTANavLink}
              >
                <div className="btn__container btn--primary btn--hover">
                  <span className="btn__text">
                    {t("Page.Home.linkParticipation")}
                  </span>
                </div>
              </NavLink>
              <p className="small redirect-terms">
                <NavLink
                  to={GLOBAL_CONFIG.Route.terms}
                  exact
                  data-event="conditions_of_participation"
                  data-category="FAQ Page"
                  data-action="Click on Link"
                  data-label="ENG_FAQ_OTHER"
                  onClick={appGoogleTracking.processEventCTANavLink}
                  target="_blank"
                >
                  Teilnahmebedingungen
                </NavLink>{" "}
                der Aktion
              </p>
            </div>
          </div>
        </Aux>
      );
    }
  }
  return <div className="s-content-steps">{contenStep}</div>;
};

export default StepContent;
