import React, { Component, useState } from 'react';
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';
import Aux from '../../hoc/Auxiliare';
import GLOBAL_CONFIG from '../../config/config';
import appGoogleTracking from '../../modules/googletracking';
import { mainLoaderToggle } from '../../modules/helpers';
import StepContent from '../../components/Content/StepContent/StepContent';
import { NavLink } from 'react-router-dom';

class Faq extends Component {
    constructor() {
        super();
        this.Mapping = [
            "one",
            "two",
            "three",
            "four",
            "five"
        ]
        this.state = {
            isMobile: false,
            isImageVisible: true
        }
        this.resize = this.resize.bind(this);
        this.handleCloseImage = this.handleCloseImage.bind(this)
    }
    
    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({isMobile: window.innerWidth <= 768});
    }
    
    componentDidMount(){
        const { t } = this.props;
        //Remove main loader
        mainLoaderToggle('hide');

        const elementSelector = document.querySelectorAll('.content-faq p a'),
            elementSelectorLength = elementSelector.length;

        for(let i=0; i < elementSelectorLength; i++){
            elementSelector[i].addEventListener('click', function(event){
                appGoogleTracking.dataLayerPush({
                    'dataEvent': 	'go_to_contact_us',
                    'dataCategory': 'FAQ Page',
                    'dataAction': 	'Go to Page',
                    'dataLabel': 	'ENG_FAQ_PART'
                });
            });
        }

         //Virtual page
        appGoogleTracking.dataLayerPush({
            'customEvent': {
                'event': 'virtual-page',
                'virtualUri': GLOBAL_CONFIG.Route.faq,
                'pageTitle': t('Meta.title')
            }
        });
        window.addEventListener("resize", this.resize);
        this.resize();
    }

    componentWillUnmount(){
        //Show mainloader
        mainLoaderToggle('show');
    }

    displayTitle(t){
        if(window.PROMOTION_CAMPAIGN == "rewe"){
            return (
                <div className="s-content-title">
                    <h1>
                        FAQ´ s - Die vollständigen TNB´ s findest du <NavLink to={GLOBAL_CONFIG.Route.terms} exact data-event="button-click" data-category="Click Action" data-action="Terms" onClick={appGoogleTracking.processEventCTANavLink}><>hier.</></NavLink>
                    </h1>
                </div>
            )
        } else if(window.PROMOTION_CAMPAIGN == "muller"){
            return (
                <div className="s-content-title">
                    <h1>
                        So einfach geht’s
                    </h1>
                </div>
            )
        }else if(window.PROMOTION_CAMPAIGN == "kaufland"){
            return (
                <div className="s-content-title">
                    <h1>
                        So geht’s
                    </h1>
                </div>
            )
        }
        return null;
    }

    handleCloseImage() {
        this.setState({ isImageVisible: false });
    }

    render() {
        const { t } = this.props;
        const { isImageVisible } = this.state;
        let classNameDeco = "content-faq-deco ";
        if(window.PROMOTION_CAMPAIGN === "rewe"){
            classNameDeco += "rewe"
        }
        return (
            <Aux>
                <div className={`content-home content-faq__${window.PROMOTION_CAMPAIGN}`}>
                    {this.displayTitle(t)}
                    <StepContent title={ this.state.isMobile ? t('Page.Faq.stepsTitle.mobile') : t('Page.Faq.stepsTitle.desktop')} t={t} />
                    <div className="content-faq s-content-copy">
                        { window.PROMOTION_CAMPAIGN === "netto" ? 
                            null 
                            : 
                            <div className={classNameDeco}>
                                {
                                    t("Page.Faq.decoration", { returnObjects: true }).map((item, index) => {
                                        return <img key={index} src={item} alt={`Decoration ${index}`} className={this.Mapping[index]}/>
                                    })
                                }
                            </div>
                        }

                        <div className="s-content-copy__item">
                            <p className="headings" style={{paddingBottom:'0px'}}><strong>Wie kann ich teilnehmen und den Kaufbeleg hochladen?</strong></p>
                            <p className="headings" style={{marginTop: '5px'}}><strong>Die Teilnahme ist ganz einfach:</strong></p>
                            <ol>
                                <li>Zwischen dem 14.10. und 24.12.2024 in einem Kaufakt Milka Produkte für 5 € kaufen. </li>
                                <li>Kaufbeleg bis zum 24.12.2024 {" "}
                                    <NavLink 
                                    to={`${GLOBAL_CONFIG.Route.participation}`}
                                    data-event="upload_receipt_link" 
                                    data-category="FAQ Page"
                                    data-action="Click on Link" 
                                    data-label="GAME_FAQ_PART" 
                                    onClick={appGoogleTracking.processEventCTANavLink}
                                    >hier</NavLink> hochladen.</li>
                                <li> Attraktive Preise im Gesamtwert von über 100.000 € gewinnen.</li>
                            </ol>
                            <br/> 
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings"><strong>Mit welchen Produkten kann ich teilnehmen? </strong></p>
                            <p>Du kannst mit allen Milka Produkten teilnehmen, die du vom 14.10. bis zum 24.12.2024 in den teilnehmenden Märkten kaufst. </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p id="scrollToKaufbeleg" className="headings"><strong>Wie muss der Kaufbeleg aussehen?</strong></p>
                            <p>Achte darauf, das diese Angaben vorhanden und gut zu erkennen sind: </p>
                            <ol>
                                <li>Einkaufsstätte </li>
                                <li>Milka Produkt </li>
                                <li>Kaufpreis der Milka Produkte</li>
                                <li>Gesamtwert deines Kaufbelegs</li>
                                <li>Einkaufsdatum</li>
                                <li>Rechnungsnummer</li>
                            </ol>
                            <br/> 
                            <div className="s-content-copy__item">
                                <div className="s-content-copy__item">
                                    <div className="s-content-copy__item">
                                        {isImageVisible && (
                                            <div className="s-content-copy__item-image">
                                                <img src='resources/images/xmas/kassenbon-desktop.png' alt='Desktop Image' className='desktop-image' />
                                                <img src='resources/images/xmas/kassenbon-mobile.png' alt='Mobile Image' className='mobile-image' /> 
                                                <button  
                                                    className='close-button' 
                                                    onClick={this.handleCloseImage}>
                                                    <img src='resources/images/xmas/close-button.png' alt='Mobile Image'  /> 
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <p className="headings"><strong>Wie lange kann ich an der Aktion teilnehmen?</strong></p>
                                <p>Du kannst bis einschließlich 24.12.2024 deinen Kaufbeleg hochladen und teilnehmen.</p>
                                <p className="headings"><strong>Wie oft kann ich teilnehmen?</strong></p>
                                <p>Du kannst 1x pro Tag teilnehmen. Jeder Kaufbeleg nur einmal gültig. </p>
                            </div>
                            <div className="s-content-copy__item">
                                <p className="headings"><strong>Kann ich einen Kaufbeleg mehrmals nutzen?</strong></p>
                                <p>Nein, jeder Kaufbeleg ist nur einmal gültig.</p>
                            </div>
                            <div className="s-content-copy__item">
                                <p className="headings"><strong>Kann jeder an der Aktion teilnehmen?</strong></p>
                                <p>Teilnehmen kann jede natürliche Person ab 18 Jahren mit Wohnsitz in Deutschland. Mitarbeiter von Mondelez Deutschland und seinen Konzern- und Schwestergesellschaften sowie der eingebundenen Vertragspartner und ihrer unmittelbaren Familienangehörigen sind von einer Teilnahme an der Aktion ausgeschlossen. </p>
                            </div>
                            <div className="s-content-copy__item">
                                <p className="headings"><strong>Gewinnen alle Teilnehmer? </strong></p>
                                <p>Ja, jede Person, die an dieser Milka Aktion teilnimmt, erhält garantiert einen Gewinn. Die Auswahl der Gewinne erfolgt per Zufallsprinzip.</p>
                                <div className="s-content-copy__item">
                                    <p className="headings"><strong>Wie erhalte ich meinen Gewinn? </strong></p>
                                    <p> Im Aktionszeitraum versendet Milka originellen Milka Merch, Einkaufsgutscheine und TUI Reisegutscheine im Gesamtwert von über 100.000 €. Der Versand erfolgt wöchentlich nach Prüfung der Kassenbelege. Du wirst innerhalb von ca. 7 Tagen nach Teilnahme per E-Mail informiert und erhältst deinen Milka Gewinn anschließend. </p>
                                    <ul>
                                        <strong>
                                            <li>Die TUI Online-Reisegutscheinen werden per E-Mail verschickt</li>
                                            <li>Die Gutscheine im Wert von 5€, 10€ oder 50€ von  Kaufland und REWE werden per E-Mail verschickt</li>
                                            <li>Die Gutscheine von  Edeka und Müller werden per Post verschickt</li>
                                            <li>Die YETI Rambler® 42 oz (1242 ml) Trinkbecher mit Trinkhalmdeckel werden per Post verschickt</li>
                                            <li>Die Milka Socken werden per Post verschickt</li>
                                            <li>Die TUI Rabattcodes werden per E-Mail verschickt</li>
                                        </strong> 
                                    </ul>
                                </div>
                                <div className="s-content-copy__item">
                                    <p className="headings"><strong>Endet die Aktion am 24.12.2024?</strong></p>
                                    <p>Ja, bis einschließlich 24.12.2024 kannst du Kaufbelege deiner Einkäufe <a href="/mitmachen">hier</a> hochladen, das virtuelle Milka Überraschungsgeschenk öffnen und gewinnen. Danach ist die Aktion beendet.</p>
                                </div>
                                <div className="s-content-copy__item">
                                    <p className="headings"><strong>Kann ich meine Daten löschen?</strong></p>
                                    <p>Nach Ende der Aktion werden die Teilnehmerdaten gelöscht. Soweit keine gesetzlichen Aufbewahrungspflichten bestehen, erfolgt eine umgehende Löschung der Daten, nachdem die Aktion abgewickelt ist. Teilnahmedaten sind aus gesetzlichen und buchhalterischen Gründen nach Maßgabe der §§ 257 HGB, 147 AO, 14b UStG aufzubewahren. Für diese Speicherung der Teilnehmerdaten ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. c) DS-GVO, da die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der Mondelez Deutschland unterliegt. Aber falls du deine Daten bereits während des Aktionszeitraums löschen möchtest, ist das natürlich möglich. Dazu wendest du dich bitte direkt an unseren {" "}
                                        <a href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" 
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            data-event="go_to_contact_us"
                                            data-category="FAQ Page" 
                                            data-action="Go to Page" 
                                            data-label="END_FAQ_PART" 
                                            >Verbraucherservice
                                        </a>*. <br/> Eine Teilnahme an der Aktion ist dann leider nicht mehr möglich. Weitere Informationen zum Umgang mit deinen Daten im Rahmen dieser Aktion findest du in den Teilnahmebedingungen. </p>
                                </div>
                                <div className="s-content-copy__item">
                                    <p className="headings"><strong>An wen kann ich mich wenden, wenn ich weitere Fragen habe?</strong></p>
                                    <p> Vielleicht hast du noch weitere Fragen, die hier nicht beantwortet worden sind. Dann wende dich gerne direkt an unseren {" "}
                                         <a href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D" 
                                            target="_blank"
                                            rel="noopener noreferrer" 
                                            data-event="go_to_contact_us" 
                                            data-category="FAQ Page" 
                                            data-action="Go to Page" 
                                            data-label="END_FAQ_PART"
                                          >Verbraucherservice</a> *. </p>
                                    <p style={{fontSize:'16px'}}>*Falls der hier hinterlegte Link zum Verbraucherservice nicht funktioniert, kannst du über den Kontaktlink im Bereich „Service“ den Verbraucherservice erreichen. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}

export default withTranslation()(Faq);