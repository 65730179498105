import React, { useState } from 'react';
import appGoogleTracking from '../../modules/googletracking';

/*
    Google tagging plan added on links using : appGoogleTracking.processEventCTA
*/

const Header = () => {
    const [value, setValue] = useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    }
    return (
        <header>
            <nav className="js-header header__container">
                <div className="header__head">
                    <img className="header__pull js-pull-animate" src="resources/images/icons/Pull_Out_Shape.svg" alt="print" />
                    <div className="js-hamburger header__menu" id="hamburger-m"><span className="menu-line"></span><span className="menu-line"></span> <span className="menu-line"></span><span className="menu-line"></span></div>
                    <div className="header__logo">
                        <a href="https://www.milka.de/" target="_blank" rel="noopener noreferrer"><span className="icon-milka-logo"></span></a>
                    </div>
                    <div className="header__icons">
                        <a
                            href='https://fcmilka.de/account'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className="icon-profile"></span>
                        </a>
                        <a
                            href='https://www.milka.de/newsletter'
                            target="_blank"
                            rel="noopener noreferrer"
                            data-event="header_contact_menu"
                            data-category="Header"
                            data-action="Select Menu"
                            data-label="NEWSLETTER"
                            onClick={appGoogleTracking.processEventCTA}
                        >
                            <span className="newsletter">
                            </span>
                        </a>
                    </div>
                </div>
                <div className="header__content js-ham-content">
                    <div className="header__head">
                        <div className="js-ham-close"><span className="icon-home"></span></div>
                    </div>
                    <div className="header__navigations">
                        <ul>
                            <li>
                                <a className="header__navigation js-event-cta" data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="PRODUKTE" target="_blank" rel="noopener noreferrer" href="https://www.milka.de/alle-produkte" onClick={appGoogleTracking.processEventCTA}>PRODUKTE</a>
                            </li>
                            <li>
                                <a className="header__navigation js-event-cta" data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="NEUES" target="_blank" rel="noopener noreferrer" href="https://www.milka.de/neues" onClick={appGoogleTracking.processEventCTA}>NEUES</a>
                            </li>
                            <li>
                                <a className="header__navigation js-event-cta" data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="FC MILKA" target="_blank" rel="noopener noreferrer" href="https://www.fcmilka.de/" onClick={appGoogleTracking.processEventCTA}>FC MILKA</a>
                            </li>
                            <li>
                                <a className="header__navigation js-event-cta" data-event="info-click" data-category="Informational Action" data-action="Header Tab" data-label="UBER MILKA" target="_blank" rel="noopener noreferrer" href="https://www.milka.de/uber-milka" onClick={appGoogleTracking.processEventCTA}>ÜBER MILKA</a>
                            </li>
                        </ul>
                        <div className="header__end"></div>
                    </div>
                </div>
            </nav>
            <nav className="js-header header-md__container">
                <div className="header-md__logo">
                    <a className="nav__item js-event-cta" data-event="go_to_milka" data-category="Header" data-action="Go to Page" data-label="MILKA_ALLPAGE_NAVBAR" onClick={appGoogleTracking.processEventCTA} href="https://www.milka.de/" target="_blank" rel="noopener noreferrer"><span className="icon-milka-logo"></span></a>
                </div>
                <div className="js-header-md-hamburger header-md__menu">
                    <div className="header__btn" id="hamburger"><span className="menu-line"></span><span className="menu-line"></span><span className="menu-line"></span><span className="menu-line"></span></div>
                </div>
                <div className="js-header-md-content header-md__content">
                    <ul className="header-md__navigations">
                        <li>
                            <a className="header-md__navigation js-event-cta" data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="PRODUKTE" href="https://www.milka.de/alle-produkte" target="_blank" rel="noopener noreferrer" onClick={appGoogleTracking.processEventCTA}>PRODUKTE</a>
                        </li>
                        <li>
                            <a className="header-md__navigation js-event-cta" data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="NEUES" href="https://www.milka.de/neues" target="_blank" rel="noopener noreferrer" onClick={appGoogleTracking.processEventCTA}>NEUES</a>
                        </li>
                        <li>
                            <a className="header-md__navigation js-event-cta" data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FC MILKA" target="_blank" rel="noopener noreferrer" href="https://www.fcmilka.de/" onClick={appGoogleTracking.processEventCTA}>FC MILKA</a>
                        </li>
                        <li>
                            <a className="header-md__navigation js-event-cta" data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="UBER MILKA" href="https://www.milka.de/uber-milka" target="_blank" rel="noopener noreferrer" onClick={appGoogleTracking.processEventCTA}>ÜBER MILKA</a>
                        </li>
                    </ul>
                    <div className="header-md__search-outter-container">
                        <a
                            href={'https://www.milka.de/Search-Page?search=' + value}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-event="search"
                            data-category="Header"
                            data-action="Click on Search"
                            data-label="ENG_ALLPAGE_NAVBAR"
                            onClick={appGoogleTracking.processEventCTA}
                        >
                            <div className="search-container">
                                <span
                                    className="icon-search-mobile text-lg icon-search"
                                ></span>
                                <div className="">
                                    <input
                                        placeholder="Suchbegriff eingeben"
                                        className="searchInput placeholder-opacity-[0.5]"
                                        name="search-box"
                                    />
                                </div>
                            </div>
                        </a>
                    </div>
                    <ul className="header-md__icons">
                        <li className="header-md__icon header-md__contact ">
                            <a
                                href='https://fcmilka.de/account'
                                target="_blank"
                                rel="noopener noreferrer"
                                data-event="header_contact_menu"
                                data-category="Header"
                                data-action="Select Menu"
                                data-label="ACCOUNT"
                                onClick={appGoogleTracking.processEventCTA}
                            >
                                <span className="icon-profile"></span>
                            </a>
                        </li>
                        <li className="header-md__icon header-md__contact">
                            <a
                                href='https://www.milka.de/newsletter'
                                target="_blank"
                                rel="noopener noreferrer"
                                data-event="header_contact_menu"
                                data-category="Header"
                                data-action="Select Menu"
                                data-label="NEWSLETTER"
                                onClick={appGoogleTracking.processEventCTA}
                            >
                                <span className="newsletter">
                                </span>
                            </a>
                        </li>
                        <li className="header-md__icon header-md__contact">
                            <a
                                className="js-event-cta"
                                href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D"
                                target="_blank"
                                rel="noopener noreferrer"
                                data-event="header_contact_menu"
                                data-category="Header"
                                data-action="Select Menu"
                                data-label="CONTACT"
                                onClick={appGoogleTracking.processEventCTA}
                            >
                                <span className="icon-contact"></span>
                            </a>
                        </li>
                        <li className="header-md__icon header-md__globe">
                            <a
                                href="https://www.milka.de/"
                                target="_blank"
                                rel="noopener noreferrer"
                                data-event="header_contact_menu"
                                data-category="Header"
                                data-action="Select Menu"
                                data-label="GLOBE"
                                onClick={appGoogleTracking.processEventCTA}
                            >
                                <img src='resources/icons/icon-globe.png' alt='icon-globe' className='icon-globe' />
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Header;