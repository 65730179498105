import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import Aux from '../../hoc/Auxiliare';
import parse from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import GLOBAL_CONFIG from '../../config/config';
import appGoogleTracking from '../../modules/googletracking';
import { mainLoaderToggle } from '../../modules/helpers';
import TitleContent from '../../components/Content/TitleContent/TitleContent';
import GiftContent from '../../components/Content/GiftContent/GiftContent';
import GiftContentFooter from '../../components/Content/GiftContent/GiftContentFooter';
class Prize extends Component {

    componentDidMount(){
        const { t } = this.props;
        //Remove main loader
        mainLoaderToggle('hide');

        const elementSelector = document.querySelectorAll('.btn__container'),
        elementSelectorLength = elementSelector.length;

        for(let i=0; i < elementSelectorLength; i++){
          elementSelector[i].addEventListener('click', function(event){
              appGoogleTracking.dataLayerPush({
                  'dataEvent': 	'join_now',
                  'dataCategory': 'Prizes Page',
                  'dataAction': 	'Click on Join Now',
                  'dataLabel': 	'GAME_PP_PART'
              });
          });
      }
  

        //Virtual page
        appGoogleTracking.dataLayerPush({
            'customEvent': {
                'event': 'virtual-page',
                'virtualUri': GLOBAL_CONFIG.Route.prize,
                'pageTitle': t('Meta.title')
            }
        });
    }

    componentWillUnmount(){
        //Show mainloader
        mainLoaderToggle('show');
    }

    render() {

        const { t } = this.props;

        let promotionCampaign = window.PROMOTION_CAMPAIGN;

        let image = {
            desktop: '/resources/images/'+promotionCampaign+'/milka-prize.png',
            mobile: '/resources/images/'+promotionCampaign+'/milka-prize-mobile.png'
        };

        let options = {
            btnShow: true,
            hrShow: false
        };

        return (
          <Aux>
            <div
              className={`content-prize content-prize__${window.PROMOTION_CAMPAIGN}`}
              style={{ marginBottom: "50px" }}
            >
              {window.PROMOTION_CAMPAIGN === "kaufland" ? (
                <>
                  <TitleContent
                    heading={parse(t("Page.Prize.title"))}
                    paragraph={parse(t("Page.Prize.paragraph"))}
                  />
                  <div className="s-content-title">
                    <NavLink
                      to={GLOBAL_CONFIG.Route.participation}
                      exact
                      data-event="join_now"
                      data-category="Prizes Page"
                      data-action="Click on Join Now"
                      data-label="GAME_PRIZE_PART"
                    >
                      <div className="btn__container btn--primary btn--hover">
                        <span className="btn__text">JETZT MITMACHEN</span>
                      </div>
                    </NavLink>
                  </div>
                  <GiftContent
                    imageoption={image}
                    promotionCampaign={promotionCampaign}
                  />
                </>
              ) : (
                <>
                  <TitleContent
                    heading={parse(t("Page.Prize.title"))}
                    subheading={
                      typeof t("Page.Prize.subtitle") === "string"
                        ? parse(t("Page.Prize.subtitle"))
                        : null
                    }
                    paragraph={parse(t("Page.Prize.paragraph"))}
                    bullet={
                      Array.isArray(t("Page.Prize.bulletPoints"))
                        ? t("Page.Prize.bulletPoints")
                        : [
                            "TUI Online-Reisegutscheine im Wert von je 3.000€",
                            "Händlergutscheine im Wert von 5€, 10€ oder 50€",
                            "YETI Rambler® 42 oz (1242 ml) Trinkbecher mit Trinkhalmdeckel",
                            "Trendige Socken im Milka Design",
                            "Rabatt-Coupons auf deine nächste Reise mit TUI",
                          ]
                    }
                    paragraph2={parse(t("Page.Prize.paragraph2"))}
                  />

                  {this.DisplayPrizeDetails()}
                </>
              )}
              {/* <GiftContentFooter options={options} /> */}
            </div>
          </Aux>
        );
    }

    DisplayPrizeDetails() {
      let buttonText="Zur startseite";
      switch(window.PROMOTION_CAMPAIGN){
        case "edeka":
          buttonText = "JETZT MITMACHEN"
          break
        case "kaufland":
          buttonText ="JETZT MITMACHEN"
          break
        case "netto":
          buttonText ="ZURÜCK ZUR STARTSEITE"
          break
        case "budni":
          buttonText ="ZUR STARTSEITE"
          break
        case "xmas":
          buttonText= "JETZT MITMACHEN"
      }
      return (
        <div className="s-content-prize">
          <div className="container s-content-prize__gewinne">
          <NavLink
            to={`${GLOBAL_CONFIG.Route.participation}`}
          style={{marginTop:"20px"}}
              rel="noopener noreferrer"
              data-event="join_now"
              data-category="Prizes Page"
              data-action="Click on Join Now"
              data-label="GAME_PP_PART"
            >
              <button className="btn__container btn--primary btn--hover">{buttonText}</button>
            </NavLink>
            <picture style={{marginTop:"50px"}}>
              <source
                srcSet="/resources/images/xmas/GroupDesktop.png"
                media="(min-width: 1024px)"
              />
              <source
                srcSet="/resources/images/xmas/GroupDesktop.png"
                media="(min-width: 481px)"
              />
              <source
                srcSet="/resources/images/xmas/GroupMobile.png"
                media="(min-width: 200px)"
              />
              <img
                src="/resources/images/xmas/GroupDesktop.png"
                alt="Productss"
              />
            </picture>
            
          </div>
        </div>
      );
    }
}

export default withTranslation()(Prize);