import React from 'react';
import appGoogleTracking from '../../../modules/googletracking';

const footercopyrightV1 = () => (
    <div className="footer__copyright_v1">
        <a href="https://www.milka.de/Static/mondelez-international"
            target="_blank"
            rel="noopener noreferrer"
            data-event="go_to_mondelez"
            data-category="Footer"
            data-action="Go to Page"
            data-label="ENG_ALLPAGE_FOOTER"
            onClick={appGoogleTracking.processEventCTA}
        >
            <span className="icon-mdlz"></span>
        </a>
        <h4>2024 MONDELEZ DEUTSCHLAND SERVICES GMBH &amp; co. kg - <span className='text-small'> Alle Rechte Vorbehalten </span> </h4>
    </div>
);

export default footercopyrightV1;