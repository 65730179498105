//Milka Christmas POS 2021 - some optimization was done from the previous project

const GLOBAL_CONFIG = {
  /* 
        Main Settings
    */
  Settings: {
    localDev: false, //Need to be false on staging and prod
    campaign: true, //True or false
    campaignStatus: 'main', //main, holding, end
    localDomain: 'localhost',
    defaultSite: 'xmas', //rewe, kaufland, muller, edeka, netto, budni,xmas
    fileStatus: false,
    pageSection: '',
    innerLink: false,
    prizeAvailable: true
  },

  /*.

        URL for webservices
    */
  ServiceSettings: {
    //Webservices
    campaignURL: '/status',
    particationURL: '/participate',
  },

  /*
        URLs for the different page
  */
  Route: {
    home: '/',
    holding: '/holding',
    end: '/',
    prize: '/gewinne', // prize
    participation: '/mitmachen', //particpation
    gift: '/gift', //gift
    thankyou: '/confirm', //Thank you
    product: '/produkte', //product
    faq: '/faq', //faq
    terms: '/teilnahmebedingungen', //terms
    cookie: '/cookie', //cookie
    spin: '/glucksrad', //cookie
    confirmation: '/confirmation',
    participationError: '/confirmation-error',
  },

  /* 
        Google recaptcha config
    */
  Captcha: {
    siteKey: '6Lena10iAAAAAALY5qWFaMxHRlF0utRCJRTlim-A',
    language: 'de',
  },

  /*
        GTM ID : Google gtm scripts
    */
  GTM: {
    gtmTag: true, //true or false
    gtmIDRewe: 'GTM-WXNN86L', //need to put the correct one - dev GTM-000000
    gtmIDKaufland: 'GTM-M76THVK', //GTM-M76THVK
    gtmIDMuller: 'GTM-N998ZM7',
  },

  Session: {
    userstatus: 'userstatus',
    userinfo: 'userinfo',
    userfile:'fileData'
  },

  JSON: {
    rewe: '/resources/localisation/rewe_localisation.json',
    kaufland: '/resources/localisation/kaufland_localisation.json',
    muller: '/resources/localisation/muller_localisation.json',
    netto: '/resources/localisation/netto_localisation.json',
  },

  Localisation: {
    path: '/resources/localisation/',
    version: 'K74036SQ97E1AF857WDF9S5D',
  },

  Date: {
    rewe: {
      start: '16.10.2023',
      end: '24.12.2023',
    },
    kaufland: {
      start: '16.10.2023',
      end: '24.12.2023',
    },
    muller: {
      start: '16.10.2023',
      end: '24.12.2023',
    },
    edeka: {
      start: '16.10.2023',
      end: '24.12.2023',
    },
    netto: {
      start: '2.10.2023',
      end: '24.12.2023',
    },
    budni: {
      start: '16.10.2023',
      end: '24.12.2023',
    }
  },
}

export default GLOBAL_CONFIG