import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';
import GLOBAL_CONFIG from '../../config/config';
import appGoogleTracking from '../../modules/googletracking';
import { mainLoaderToggle, scrollToElement, navParticipationMenu, } from '../../modules/helpers';
import TitleContent from '../../components/Content/TitleContent/TitleContent';
import CampaignServices from "../../Services/Campaign";
import Scrolltop from '../../modules/scrolltop';
import ValidateForm from '../../modules/validate';

const winnerPrize = {
  santa: "/resources/images/wheelspin/Santa.png",
  edeka: "/resources/images/wheelspin/Edeka.png",
  fiveeuro: "/resources/images/wheelspin/5.png",
  teneuro: "/resources/images/wheelspin/10.png",
  fiftyeuro: "/resources/images/wheelspin/50.png",
  fiveeuroNetto: "/resources/images/wheelspin/5-netto.png",
  teneuroNetto: "/resources/images/wheelspin/10-netto.png",
  fiftyeuroNetto: "/resources/images/wheelspin/50-netto.png",
  fiveeuroBudni: "/resources/images/wheelspin/5-budni.png",
  teneuroBudni: "/resources/images/wheelspin/10-budni.png",
  fiftyeuroBudni: "/resources/images/wheelspin/50-budni.png",

}
const ISWINNER = {
  WIN: 1,
  LOOSE: 0
}
const WINNER_CODE = {
  FIFTYEURO: 1,
  TENEURO: 2,
  FIVEEURO: 3,
  CASHBACK: 4,
  BIENENWACHSTUCH: 5
}

const SPINDEGREEEDEKA = [
  { id: 1, class: "one", deg: 0, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 2, class: "two", deg: 30, winCode: WINNER_CODE.TENEURO },
  { id: 3, class: "three", deg: 60, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 4, class: "four", deg: 90, winCode: WINNER_CODE.FIVEEURO },
  { id: 5, class: "five", deg: 120, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 6, class: "six", deg: 150, winCode: WINNER_CODE.CASHBACK },
  { id: 7, class: "seven", deg: 180, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 8, class: "eight", deg: 210, winCode: WINNER_CODE.FIFTYEURO },
  { id: 9, class: "nine", deg: 240, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 10, class: "ten", deg: 270, winCode: WINNER_CODE.FIVEEURO },
  { id: 11, class: "eleven", deg: 300, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 12, class: "twelve", deg: 330, winCode: WINNER_CODE.CASHBACK },
];
const SPINDEGREENETTO = [
  { id: 1, class: "one", deg: 0, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 2, class: "two", deg: 30, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 3, class: "three", deg: 60, winCode: WINNER_CODE.FIVEEURO },
  { id: 4, class: "four", deg: 90, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 5, class: "five", deg: 120, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 6, class: "six", deg: 150, winCode: WINNER_CODE.TENEURO },
  { id: 7, class: "seven", deg: 180, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 8, class: "eight", deg: 210, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 9, class: "nine", deg: 240, winCode: WINNER_CODE.FIVEEURO },
  { id: 10, class: "ten", deg: 270, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 11, class: "eleven", deg: 300, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 12, class: "twelve", deg: 330, winCode: WINNER_CODE.FIFTYEURO },
];
const SPINDEGREEBUDNI = [
  { id: 1, class: "one", deg: 0, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 2, class: "two", deg: 30, winCode: WINNER_CODE.TENEURO },
  { id: 3, class: "three", deg: 60, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 4, class: "four", deg: 90, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 5, class: "five", deg: 120, winCode: WINNER_CODE.FIVEEURO },
  { id: 6, class: "six", deg: 150, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 7, class: "seven", deg: 180, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 8, class: "eight", deg: 210, winCode: WINNER_CODE.FIFTYEURO },
  { id: 9, class: "nine", deg: 240, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 10, class: "ten", deg: 270, winCode: WINNER_CODE.BIENENWACHSTUCH },
  { id: 11, class: "eleven", deg: 300, winCode: WINNER_CODE.FIVEEURO },
  { id: 12, class: "twelve", deg: 330, winCode: WINNER_CODE.BIENENWACHSTUCH },
];
class WheelSpin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      spinDegree: SPINDEGREEEDEKA,
      firstRotate: true,
      prizeList: [],
      WinCode: -1,
      isWin: -1,
      ParticipationId: "",
      Consumerid: "",
      loaderSubmit: false,
      loaderGeneralError: false,
      genericErrorMessage: "",
      showPopup: false,
      showCashback: false,
      errorSalutation: false,
      errorFirstName: false,
      errorLastName: false,
      errorIban: false
    };
    // this.ProcessFormData = this.ProcessFormData.bind(this);
  }

  componentWillMount() {
    if (sessionStorage.getItem(GLOBAL_CONFIG.Session.userstatus) === null) {
      //Go to particaption page
      this.props.history.push({ pathname: GLOBAL_CONFIG.Route.participation });
    }

    switch (window.PROMOTION_CAMPAIGN) {
      case "edeka":
        this.setState({ spinDegree: SPINDEGREEEDEKA })
        break
      case "budni":
        this.setState({ spinDegree: SPINDEGREEBUDNI })
        break
      case "netto":
        this.setState({ spinDegree: SPINDEGREENETTO })
        break
    }
    // TO TEST LOCALLY
    // this.state.WinCode = WINNER_CODE.CASHBACK;
    // this.state.isWin =ISWINNER.WIN
  }

  componentDidMount() {
    const { t } = this.props;
    //Active menu participation - mitmachen
    // navParticipationMenu(true);
    //Remove main loader
    mainLoaderToggle('hide');

    if (document.querySelector(`.parent__wheel__${window.PROMOTION_CAMPAIGN}`)) {
      document.querySelector(`.parent__wheel__${window.PROMOTION_CAMPAIGN}`).scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    //Virtual page
    appGoogleTracking.dataLayerPush({
      'customEvent': {
        'event': 'virtual-page',
        'virtualUri': GLOBAL_CONFIG.Route.thankyou,
        'pageTitle': t('Meta.title'),
        'ParticipantId': window.PARTICIPATION_ID
      }
    });



    const spinContent = this.spinContentRef;
    const prizeList = [];
    // if(this.state.isWin == ISWINNER.WIN){

    for (let i = 0; i < spinContent.childNodes.length; i++) {
      if (spinContent.childNodes[i].className != undefined) {
        prizeList.push(spinContent.childNodes[i].className);
      }
    }
    this.setState({ prizeList });
    // }



  }

  componentWillUnmount() {
    //Remove active menu participation - mitmachen

    //Show mainloader
    mainLoaderToggle('show');
  }

  DisplayCards(t) {
    const boxs = t("Page.Confirmation.boxs", { returnObjects: true });
    return (
      <div className="s-card-container">
        {
          boxs.map(({ img, platzhalter, title, copy, button }) => (
            <div className="s-card">
              <img src={img} alt="" />
              {platzhalter ? (<img src={platzhalter} alt="" className="platzhalter" />) : null}
              <div className="s-card__copy">
                <h3>{parse(title)}</h3>
                <p>{parse(copy)}</p>
                <a href={button.href} target="_blank">
                  <div className="btn__container btn--secondary-lilac btn--hover">
                    <span className="btn__text"> {button.text} </span>
                  </div>
                </a>
              </div>
            </div>
          ))
        }
      </div>
    );
  }



  displayTitleContent(t) {
    return (
      <>
        {window.PROMOTION_CAMPAIGN == "edeka" ?
          <>
            <TitleContent
              heading={parse(t('Page.Game.title'))}
              subheading={parse(t('Page.Game.paragraph'))}
            />
            <TitleContent
              heading={parse(t('Page.Game.title2'))}
              subheading={parse(t('Page.Game.paragraph2'))}
            />
          </>
          : <TitleContent
            heading={parse(t('Page.Game.title'))}
            subheading={parse(t('Page.Game.paragraph'))}
          />
        }
      </>
    );
  }
  displayTitleContentCashBack(t) {
    return (
      <>
        {window.PROMOTION_CAMPAIGN == "edeka" &&
          <TitleContent
            heading={parse(t('Page.Game.CashbackTitle'))}
            subheading={parse(t('Page.Game.Cashbackparagraph'))}
          />

        }
      </>
    );
  }

  addAnimationOnPrize(index) {
    if (
      this.spinContentRef.querySelector("." + this.state.prizeList[index]) !=
      (null || undefined)
    ) {
      this.spinContentRef.querySelector(
        "." + this.state.prizeList[index]
      ).firstChild.style.animation = "animatePrize 0.1s";
    }
  }

  setRevertSpin(container, firstRotate, number) {
    container.style.transition = "1s ease-out";
    if (firstRotate === true) {
      container.style.transform = "rotate(-30deg)";
    } else {
      const reverse = number - 30;
      container.style.transform = "rotate(" + reverse + "deg)";
    }
  }
  handleClick = () => {

    const { firstRotate, spinDegree } = this.state;
    const container = this.containerRef;
    const btn = this.btnRef;

    btn.disabled = true;

    // Random spin values
    const indexWin = spinDegree.findIndex((prize) => prize.winCode == this.state.WinCode);

    // Calculate win angles
    let number = spinDegree[indexWin].deg;
    number = (360 * 3) - number;

    // Set beat animation on prize
    for (let i = 0; i < this.state.prizeList.length; i++) {
      setTimeout(() => {
        this.addAnimationOnPrize(i);
      }, i * 100);
    }

    container.style.backgroundImage = "url('/resources/images/wheelspin/GluecksardSpin-V1.gif')";

    // Create revert spin effect
    this.setRevertSpin(container, firstRotate, number);

    // Spin wheel
    setTimeout(() => {
      container.style.transition = "5s";
      container.style.transform = "rotate(" + number + "deg)";
    }, 1000);

    // Add prize win animation
    const spinWin = this.spinContentRef.querySelector(
      "." + spinDegree[indexWin].class
    );
    setTimeout(() => {
      spinWin.firstChild.style.animation = "animateWin 1s";
      spinWin.firstChild.style.transform = "scale(1.2,1.2)";
      btn.disabled = false;
      setTimeout(() => {
        if (this.state.WinCode == WINNER_CODE.CASHBACK) {
          this.setState({ showCashback: true });
          document.querySelector(`.s-content-title`).scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
          this.props.history.push({ pathname: GLOBAL_CONFIG.Route.spinconfirmation });
        }
      }, 5000);
    }, 5500);

    this.setState({ firstRotate: false });



  };
  handleClickV1 = () => {
    this.setState({
      loaderSubmit: true,
    })
    if (this.state.isWin == -1) {
      let dataStorage = JSON.parse(sessionStorage.getItem(GLOBAL_CONFIG.Session.userinfo));

      this.retrieveFileFromIndexedDB(dataStorage.fileinput, (files) => {
        if (files) {
          dataStorage.fileinput = files;
          let _data = new FormData();

          for (let key in dataStorage) {
            _data.append(key, dataStorage[key]);
          }

          CampaignServices.Participate({
            data: _data,
            retailer: window.PROMOTION_CAMPAIGN,
          })
            .then((response) => {
              let { success, message, ParticipationId, data } = response.data;

              if (success) {
                let { IsWin, WinCode, WinType, ParticipationId, Consumerid } = data;
                this.state.WinCode = WinCode;
                this.state.isWin = IsWin;
                this.state.ParticipationId = ParticipationId;
                this.state.Consumerid = Consumerid;
                this.setState({
                  loaderSubmit: false,
                });
                this.handleClick()
              } else {
                let errorStatus = data.Error[0];
                this.setState({
                  loaderSubmit: false,
                });

                if (!!errorStatus) {
                  switch (errorStatus) {
                    case "ALREADY_WON":
                      this.setState({ isWin: ISWINNER.WIN, showPopup: true })

                  }

                }
              }
              sessionStorage.removeItem(GLOBAL_CONFIG.Session.userstatus)
              sessionStorage.removeItem(GLOBAL_CONFIG.Session.userinfo)
            })
            .catch((error) => {
              let { Data } = error.response.data,
                errorStatus = Data.Error[0] || "";
              this.setState({
                loaderSubmit: false,
                showPopup: true
              });

            });

        } else {
          console.log('File not found.');
        }
      });

    }

  };

  spinContent() {
    return (
      <>
        <div className="head">
        </div>
        <div className="wheel__spin__container" ref={(ref) => (this.containerRef = ref)}>
          <button id="spin"
            ref={(ref) => (this.btnRef = ref)}
            onClick={this.handleClickV1}
          ></button>
          <div className="spin"
            ref={(ref) => (this.spinContentRef = ref)}
          >
            <div className="one"><img src={winnerPrize.edeka} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="two"><img src={winnerPrize.teneuro} className="wheel__spin__teneuro" alt="chocolate" /></div>
            <div className="three"><img src={winnerPrize.edeka} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="four"><img src={winnerPrize.fiveeuro} className="wheel__spin__fiveeuro" alt="chocolate" /></div>
            <div className="five"><img src={winnerPrize.edeka} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="six"><img src={winnerPrize.santa} className="wheel__spin__santa" alt="chocolate" /></div>
            <div className="seven"><img src={winnerPrize.edeka} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="eight"><img src={winnerPrize.fiftyeuro} className="wheel__spin__fiftyeuro" alt="chocolate" /></div>
            <div className="nine"><img src={winnerPrize.edeka} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="ten"><img src={winnerPrize.fiveeuro} className="wheel__spin__fiveeuro" alt="chocolate" /></div>
            <div className="eleven"><img src={winnerPrize.edeka} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="twelve"><img src={winnerPrize.santa} className="wheel__spin__santa" alt="chocolate" /></div>
          </div>
        </div>
        <img className='stern-A' src='/resources/images/wheelspin/Stern-A.png' alt="snow" />
        <img className='stern-B' src='/resources/images/wheelspin/Stern-B.png' alt="snow" />
        <img className='stern-C' src='/resources/images/wheelspin/Stern-C.png' alt="snow" />
        <img className='stern-D' src='/resources/images/wheelspin/Stern-D.png' alt="snow" />
        <div className="foot">

        </div>
        <div className="foot-snow">
          {/* <img src='/resources/images/wheelspin/snow.png'    className=""     alt="snow"/> */}
          <picture>
            <source srcSet='/resources/images/wheelspin/snow.png' media="(min-width: 1024px)" />
            <source srcSet='/resources/images/wheelspin/snow-small.png' media="(min-width: 481px)" />
            <source srcSet='/resources/images/wheelspin/snow-small.png' media="(min-width: 200px)" />
            <img src='/resources/images/wheelspin/snow.png' alt="snow" />
          </picture>
          <button
            data-event="spin_the_wheel"
            data-category="Game Page"
            data-action="Click on spin"
            data-label="GAME_GP_PART"
            ref={(ref) => (this.btnRef = ref)}
            onClick={(event) => {
              this.handleClickV1()
              appGoogleTracking.processEventCTANavLink(event);
            }}
          >
            <div className="btn__container btn--secondary-lilac btn--hover">
              <span className="btn__text">Weihnachtsrad drehen</span>
            </div>
          </button>
        </div>
        <div className='wheel__kaufland__bottom-text'>
          {/* <TitleContent 
                  paragraph={'Wenn du einen Edeka-Einkaufsgutschein gewinnst, dann tust du gleichzeitig anderen etwas Gutes: denn dann unterstützt Milka parallel die Tafel Deutschland e.V. mit einer Spende in Höhe von 5 € pro gewonnenem Gutschein.'}
                  />  */}
        </div>
      </>
    );
  }

  spinContentBudni() {
    return (
      <div className="temporary_div">
        <div className="head">
        </div>
        <div className="wheel__spin__container" ref={(ref) => (this.containerRef = ref)}>
          <button id="spin"
            ref={(ref) => (this.btnRef = ref)}
            onClick={this.handleClickV1}
          ></button>
          <div className="spin"
            ref={(ref) => (this.spinContentRef = ref)}
          >
            <div className="one"><img src={winnerPrize.edeka} className="wheel__spin__santa" alt="chocolate" /></div>
            <div className="two"><img src={winnerPrize.teneuroBudni} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="three"><img src={winnerPrize.edeka} className="wheel__spin__teneuro" alt="chocolate" /></div>
            <div className="four"><img src={winnerPrize.edeka} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="five"><img src={winnerPrize.fiveeuroBudni} className="wheel__spin__fiveeuro" alt="chocolate" /></div>
            <div className="six"><img src={winnerPrize.edeka} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="seven"><img src={winnerPrize.edeka} className="wheel__spin__santa" alt="chocolate" /></div>
            <div className="eight"><img src={winnerPrize.fiftyeuroBudni} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="nine"><img src={winnerPrize.edeka} className="wheel__spin__fiftyeuro" alt="chocolate" /></div>
            <div className="ten"><img src={winnerPrize.edeka} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="eleven"><img src={winnerPrize.fiveeuroBudni} className="wheel__spin__fiveeuro" alt="chocolate" /></div>
            <div className="twelve"><img src={winnerPrize.edeka} className="wheel__spin__edeka" alt="chocolate" /></div>
          </div>
        </div>
        <div className="foot">

        </div>
        <div className="foot-snow">

          <button
            data-event="spin_the_wheel"
            data-category="Game Page"
            data-action="Click on spin"
            data-label="GAME_GP_PART"
            ref={(ref) => (this.btnRef = ref)}
            onClick={(event) => {
              this.handleClickV1()
              appGoogleTracking.processEventCTANavLink(event);
            }}

          >
            <div className="btn__container btn--secondary-lilac btn--hover">
              <span className="btn__text">Weihnachtsrad drehen</span>
            </div>
          </button>
        </div>
      </div>
    );
  }
  spinContentNetto() {
    return (
      <div className="temporary_div">
        <div className="head">
        </div>
        <div className="wheel__spin__container" ref={(ref) => (this.containerRef = ref)}>
          <button id="spin"
            ref={(ref) => (this.btnRef = ref)}
            onClick={this.handleClickV1}
          ></button>
          <div className="spin"
            ref={(ref) => (this.spinContentRef = ref)}
          >
            <div className="one"><img src={winnerPrize.edeka} className="wheel__spin__santa" alt="chocolate" /></div>
            <div className="two"><img src={winnerPrize.edeka} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="three"><img src={winnerPrize.fiveeuroNetto} className="wheel__spin__teneuro" alt="chocolate" /></div>
            <div className="four"><img src={winnerPrize.edeka} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="five"><img src={winnerPrize.edeka} className="wheel__spin__fiveeuro" alt="chocolate" /></div>
            <div className="six"><img src={winnerPrize.teneuroNetto} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="seven"><img src={winnerPrize.edeka} className="wheel__spin__santa" alt="chocolate" /></div>
            <div className="eight"><img src={winnerPrize.edeka} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="nine"><img src={winnerPrize.fiveeuroNetto} className="wheel__spin__fiftyeuro" alt="chocolate" /></div>
            <div className="ten"><img src={winnerPrize.edeka} className="wheel__spin__edeka" alt="chocolate" /></div>
            <div className="eleven"><img src={winnerPrize.edeka} className="wheel__spin__fiveeuro" alt="chocolate" /></div>
            <div className="twelve"><img src={winnerPrize.fiftyeuroNetto} className="wheel__spin__edeka" alt="chocolate" /></div>
          </div>
        </div>
        <div className="foot">

        </div>
        <div className="foot-snow">

          <button
            data-event="spin_the_wheel"
            data-category="Game Page"
            data-action="Click on spin"
            data-label="GAME_GP_PART"
            ref={(ref) => (this.btnRef = ref)}
            onClick={(event) => {
              this.handleClickV1()
              appGoogleTracking.processEventCTANavLink(event);
            }}
          >
            <div className="btn__container btn--secondary-lilac btn--hover">
              <span className="btn__text">Weihnachtsrad drehen</span>
            </div>
          </button>
        </div>
      </div>
    );
  }

  CashbackEdeka(t) {
    // ValidateForm.validateForm("frm_participation")
    return (
      <div className="campaign-form-participation">
        <form
          id="frm_participation"
          name="frm_participation"
          method="post"
          action="/"
          onSubmit={this.ProcessFormData.bind(this)}
          noValidate
        >
          <div className="form-container" id="UserDetails">
            <div id="salutation" className="input-container">
              <div className="form-input__container form-input__dropdown">
                <select
                  ref={(select) => (this._DETAILS_salutation = select)}
                  className="form-input__input js-event-type-in js-event-ab"
                  type="text"
                  name="salutation"
                  id="salutation"
                  data-require="true"
                  data-type="ddl"
                  data-error-target="#error-salutation"
                  data-required-message=""
                  data-event-fieldname="Salutation"
                  data-event="form-click"
                  data-category="Form Action"
                  data-action="Salutation"
                  data-label="Type In"
                  onChange={(event) => {
                    if (this._DETAILS_salutation.value == "default") {
                      this.setState({ errorSalutation: true })
                      event.target.classList.add("notvalid");
                      event.target.classList.remove("valid");
                    } else {
                      this.setState({ errorSalutation: false })
                      event.target.classList.add("valid");
                      event.target.classList.remove("notvalid");
                    }
                  }}
                >
                  {t("Page.Game.CashbackForm.Sections.Details", {
                    returnObjects: true,
                  }).Field.Salutation.Options.map((salutation, index) => {
                    var { Display, Value } = salutation;
                    return (
                      <option value={Value} key={`${Value}-${index}`}>
                        {Display}
                      </option>
                    );
                  })}
                </select>

              </div>
            </div>
            <div id="firstName" className="input-container">
              <div className="form-input__container">
                {/* <label className="form-input__label" htmlFor={t("Page.Game.CashbackForm.Sections.Details", { returnObjects: true }).Field.FirstName.Id} >{t("Page.Game.CashbackForm.Sections.Details", { returnObjects: true }).Field.FirstName.Label}</label> */}
                <input
                  className="form-input__input js-event-type-in js-event-ab"
                  type={
                    t("Page.Game.CashbackForm.Sections.Details", {
                      returnObjects: true,
                    }).Field.FirstName.Type
                  }
                  name={
                    t("Page.Game.CashbackForm.Sections.Details", {
                      returnObjects: true,
                    }).Field.FirstName.Id
                  }
                  id={
                    t("Page.Game.CashbackForm.Sections.Details", {
                      returnObjects: true,
                    }).Field.FirstName.Id
                  }
                  placeholder={
                    t("Page.Game.CashbackForm.Sections.Details", {
                      returnObjects: true,
                    }).Field.FirstName.Label
                  }
                  data-require={
                    t("Page.Game.CashbackForm.Sections.Details", {
                      returnObjects: true,
                    }).Field.FirstName.Required
                  }
                  data-type="regex"
                  data-error-target={`#error-${t("Page.Game.CashbackForm.Sections.Details", {
                    returnObjects: true,
                  }).Field.FirstName.Id
                    }`}
                  data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                  data-required-message=""
                  data-pattern-message=""
                  data-event-fieldname="Firstname"
                  data-event="form-click"
                  data-category="Form Action"
                  data-action="Firstname"
                  data-label="Type In"
                  ref={(input) => (this._DETAILS_firstName = input)}
                  onBlur={(event) => {
                    if (!this._DETAILS_firstName.value) {
                      this.setState({ errorFirstName: true })
                      event.target.classList.add("notvalid");
                      event.target.classList.remove("valid");
                    } else {
                      this.setState({ errorFirstName: false })
                      event.target.classList.add("valid");
                      event.target.classList.remove("notvalid");
                    }
                  }}
                />
              </div>
            </div>
            <div id="lastName" className="input-container">
              <div className="form-input__container">
                {/* <label className="form-input__label" htmlFor={t("Page.Game.CashbackForm.Sections.Details", { returnObjects: true }).Field.LastName.Id} >{t("Page.Game.CashbackForm.Sections.Details", { returnObjects: true }).Field.LastName.Label}</label> */}
                <input
                  className="form-input__input js-event-type-in js-event-ab"
                  type={
                    t("Page.Game.CashbackForm.Sections.Details", {
                      returnObjects: true,
                    }).Field.LastName.Type
                  }
                  name={
                    t("Page.Game.CashbackForm.Sections.Details", {
                      returnObjects: true,
                    }).Field.LastName.Id
                  }
                  id={
                    t("Page.Game.CashbackForm.Sections.Details", {
                      returnObjects: true,
                    }).Field.LastName.Id
                  }
                  placeholder={
                    t("Page.Game.CashbackForm.Sections.Details", {
                      returnObjects: true,
                    }).Field.LastName.Label
                  }
                  data-require={
                    t("Page.Game.CashbackForm.Sections.Details", {
                      returnObjects: true,
                    }).Field.LastName.Required
                  }
                  data-type="regex"
                  data-error-target={`#error-${t("Page.Game.CashbackForm.Sections.Details", {
                    returnObjects: true,
                  }).Field.LastName.Id
                    }`}
                  data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                  data-required-message=""
                  data-pattern-message=""
                  data-event-fieldname="Lastname"
                  data-event="form-click"
                  data-category="Form Action"
                  data-action="Lastname"
                  data-label="Type In"
                  ref={(input) => (this._DETAILS_lastName = input)}
                  onBlur={(event) => {
                    if (!this._DETAILS_lastName.value) {
                      this.setState({ errorLastName: true })
                      event.target.classList.add("notvalid");
                      event.target.classList.remove("valid");
                    } else {
                      this.setState({ errorLastName: false })
                      event.target.classList.add("valid");
                      event.target.classList.remove("notvalid");
                    }
                  }}
                />
              </div>
            </div>
            <div id="IBAN" className="input-container">
              <div className="form-input__container">
                {/* <label className="form-input__label" htmlFor={t("Page.Game.CashbackForm.Sections.Details", { returnObjects: true }).Field.Email.Id} >{t("Page.Game.CashbackForm.Sections.Details", { returnObjects: true }).Field.Email.Label}</label> */}
                <input
                  className="form-input__input js-event-type-in js-event-ab"
                  type={
                    t("Page.Game.CashbackForm.Sections.Details", {
                      returnObjects: true,
                    }).Field.Iban.Type
                  }
                  id={
                    t("Page.Game.CashbackForm.Sections.Details", {
                      returnObjects: true,
                    }).Field.Iban.Id
                  }
                  placeholder={
                    t("Page.Game.CashbackForm.Sections.Details", {
                      returnObjects: true,
                    }).Field.Iban.Label
                  }
                  data-require={
                    t("Page.Game.CashbackForm.Sections.Details", {
                      returnObjects: true,
                    }).Field.Iban.Required
                  }
                  data-type="regex"
                  data-error-target={`#error-${t("Page.Game.CashbackForm.Sections.Details", {
                    returnObjects: true,
                  }).Field.Iban.Id
                    }`}
                  data-regex-pattern="^DE\d{20}$"
                  data-required-message=""
                  data-pattern-message="Ungültige IBAN, bitte versuchen Sie es erneut mit Ihrer IBAN"
                  data-event-fieldname="IBAN"
                  data-event="form-click"
                  data-category="Form Action"
                  data-action="Email"
                  data-label="Type In"
                  ref={(input) => (this._DETAILS_iban = input)}
                  onBlur={(event) => {
                    if (!this._DETAILS_iban.value) {
                      this.setState({ errorIban: true })
                      event.target.classList.add("notvalid");
                      event.target.classList.remove("valid");
                    } else {
                      this.setState({ errorIban: false })
                      event.target.classList.add("valid");
                      event.target.classList.remove("notvalid");
                    }
                  }}
                />

                <span
                  id={`error-${t("Page.Game.CashbackForm.Sections.Details", {
                    returnObjects: true,
                  }).Field.Iban.Id
                    }`}
                  className="form-input__error active"
                ></span>
              </div>
            </div>
          </div>
          <button type="submit"
            className="btn__container btn--primary"
          >
            DATEN SENDEN
          </button>
        </form>
      </div>
    );
  }

  popupAlreadWin() {
    return (
      <>
        <div className="js-overlay overlayV1 overlay--black">
          <div className="overlayV1__container overlay__content">
            <div
              className="overlayV1__close"
              onClick={this.onClosePopupHandler}
            ></div>
            <p id="js-error-msg">
              LEIDER HAST DU BEREITS
              TEILGENOMMEN UND GEWONNEN. JEDER TEILNEHMER KANN NUR EINMAL GEWINNEN.
            </p>
          </div>
        </div>
      </>
    )
  }

  render() {
    const { t } = this.props;
    let spinContent = null;
    let titleContent = null;
    let cashbackEdeka = null;


    if (this.state.WinCode == WINNER_CODE.CASHBACK && this.state.showCashback) {
      titleContent = this.displayTitleContentCashBack(t);
      cashbackEdeka = window.PROMOTION_CAMPAIGN === "edeka" && this.CashbackEdeka(t)
    } else {
      switch (window.PROMOTION_CAMPAIGN) {
        case "edeka": spinContent = <div className={`parent__wheel__${window.PROMOTION_CAMPAIGN}`}>{this.spinContent()} </div>
          break
        case "netto": spinContent = <div className={`parent__wheel__${window.PROMOTION_CAMPAIGN}`}>{this.spinContentNetto()} </div>
          break
        case "budni": spinContent = <div className={`parent__wheel__${window.PROMOTION_CAMPAIGN}`}>{this.spinContentBudni()} </div>
          break
      }
      titleContent = this.displayTitleContent(t);
    }



    return (
      <div className={`content-confirmation content-confirmation__${window.PROMOTION_CAMPAIGN}`}>
        {titleContent}
        {spinContent}
        {cashbackEdeka}
        {this.state.showPopup ?
          <>
            <div className="js-overlay overlayV1 overlay--black">
              <div className="overlayV1__container overlay__content">
                <div
                  className="overlayV1__close"
                  onClick={this.onClosePopupHandler}
                ></div>
                <p id="js-error-msg">
                  LEIDER HAST DU BEREITS
                  TEILGENOMMEN UND GEWONNEN. JEDER TEILNEHMER KANN NUR EINMAL GEWINNEN.
                </p>
              </div>
            </div>
          </>
          : null}
        {this.state.loaderSubmit ? (
          <div className="simple-loader__container active">
            <div className="simple-loader__indicator"></div>
          </div>
        ) : null}
        {this.state.loaderGeneralError ? (
          <div className="js-overlay overlay overlay--black">
            <div className="overlay__container overlay__content">
              <div
                className="overlay__close"
                onClick={this.onClosePopupHandler}
              ></div>
              <p id="js-error-msg">{parse(this.state.genericErrorMessage)}</p>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  ProcessFormData(event) {
    event.preventDefault();

    //Open loader
    let validateForm = ValidateForm.validateForm("frm_participation")
    console.log("Validate form", validateForm)
    if (validateForm === true) {
      this.setState({
        loaderSubmit: true,
      });
      console.log("mety")
      //Process form data

      // appGoogleTracking.processEventCTANavLink(event);

      let data = {
        salutation: this._DETAILS_salutation.value,
        firstname: this._DETAILS_firstName.value,
        lastname: this._DETAILS_lastName.value,
        iban: this._DETAILS_iban.value,
        entryId: this.state.ParticipationId,
        consumerId: this.state.Consumerid
      };

      //Form Data is used to passe the receipt as binary

      CampaignServices.CashBack({
        data: data,
        retailer: window.PROMOTION_CAMPAIGN,
      })
        .then((response) => {
          let { success, message, ParticipationId, data } = response.data;

          if (success) {
            appGoogleTracking.dataLayerPush({
              'dataEvent': 'send_data_link',
              'dataCategory': 'Cashback Page',
              'dataAction': 'Click on Send Data button link',
              'dataLabel': 'GAME_CB_PART'
            });
            this.props.history.push({ pathname: GLOBAL_CONFIG.Route.spinconfirmation });
            //Go to Thank you page
          } else {
            let errorStatus = data.Error[0];
            if (!!errorStatus) {
              // this.ProcessError(errorStatus);
              this.setState({
                loaderSubmit: false,
              });
            }

          }
        })
        .catch((error) => {
          let { Data } = error.response.data,
            errorStatus = Data.Error[0] || "";

          this.ProcessError(errorStatus);


        });
    } else {
      if (this.state.errorFirstName || this.state.errorLastName || this.state.errorIban || this.state.errorSalutation) {
        event.target.classList.add("notvalid");
        event.target.classList.remove("valid");
      }
    }
  }
  ProcessError(_ERR) {

    this.setState({
      loaderSubmit: false,
      loaderGeneralError: true,
      genericErrorMessage: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal",
    });

  }
  onClosePopupHandler() {
    window.location.replace('https://www.milka.de');
  };
  retrieveFileFromIndexedDB(fileName, callback) {
    const request = window.indexedDB.open('fileDatabase', 1);

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction('files', 'readonly');
      const objectStore = transaction.objectStore('files');

      const request = objectStore.get(fileName);

      request.onsuccess = (event) => {
        const fileData = event.target.result;

        if (fileData) {
          callback(fileData.content);
        } else {
          callback(null); // File not found
        }
      };
    };
  }
}

export default withTranslation()(WheelSpin);