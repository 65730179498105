import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import GLOBAL_CONFIG from "../../config/config";
import appGoogleTracking from "../../modules/googletracking";
import { mainLoaderToggle } from "../../modules/helpers";
import { NavLink } from "react-router-dom";
import GiftContentFooter from "../../components/Content/GiftContent/GiftContentFooter";

class ConfirmationError extends Component {
	constructor(props) {
		super(props);

		// to check if the device is a mobile or not
		this.state = {
			isMobile: false,
		};

		this.resize = this.resize.bind(this);
	}

	// componentWillMount() {
	// 	if (
	// 		sessionStorage.getItem(GLOBAL_CONFIG.Session.userstatus) === null &&
	// 		sessionStorage.getItem(GLOBAL_CONFIG.Session.userstatus) !== "participate"
	// 	) {
	// 		//Go to particaption page
	// 		this.props.history.push({ pathname: GLOBAL_CONFIG.Route.participation });
	// 		window.location.reload();
	// 	} else {
	// 		sessionStorage.removeItem(GLOBAL_CONFIG.Session.userstatus);
	// 	}
	// }

	componentDidMount() {
		window.scrollTo(0, 1000);
		const { t } = this.props;
		//Remove main loader
		mainLoaderToggle("hide");

		//Virtual page
		appGoogleTracking.dataLayerPush({
			customEvent: {
				event: "virtual-page",
				virtualUri: GLOBAL_CONFIG.Route.ConfirmationError,
				pageTitle: t("Meta.title"),
			},
		});

		window.addEventListener("resize", this.resize);
		this.resize();
	}

	componentWillUnmount() {
		//Show mainloader
		mainLoaderToggle("show");
	}

	resize() {
		const mobile = window.innerWidth <= 767;
		const tablet = window.innerWidth >= 768 && window.innerWidth <= 1024;

		this.setState({
			isMobile: mobile,
			isTablet: tablet,
		});
	}

	render() {
		const { t } = this.props;
		const data = this.props.history.location.state?.data;
		console.log("data", data);

		let options = {
			btnShow: false,
			hrShow: false,
		};

		return (
			<div className="content-confirmation content-confirmation__xmas">
				<div className="s-card-container s-content-end ">
					<h1>Deine Teilnahme war nicht erfolgreich</h1>
					{data === "DAILY_PARTICIPATION_LIMIT_EXCEEDED" && (
						<p>
							Du hast heute bereits an der Milka Weihnachtsaktion teilgenommen.
							Pro Tag ist nur eine Teilnahme möglich. <br />
							Schon morgen hast du also eine neue Chance!
						</p>
					)}

					{ data === "INVALID_RECEIPT" && (
					
						<p>
							Leider konnte dein Kaufbeleg nicht akzeptiert werden. Schau am
							besten direkt in unsere{" "}
							<NavLink
								to={GLOBAL_CONFIG.Route.faq}
								exact
								data-event="go_to_faq"
								data-category="Confirmation Page"
								data-action="Go to page"
								data-label="ENG_CP_OTHER"
								onClick={(e) => {
									appGoogleTracking.processEventCTANavLink(e);
									setTimeout(() => {
										const faqSection = document.getElementById('scrollToKaufbeleg');
										if (faqSection) {
											faqSection.scrollIntoView({ behavior: 'smooth' });
										}
									}, 200);
								}}
							>
								<span
									style={{ textDecoration: "underline" }}
									className="btn__text"
								>
									FAQ
								</span>
							</NavLink>
							.<br />
							Dort findest du eine genaue Beschreibung, welche Angaben auf dem
							Kaufbeleg vorhanden sein müssen.
							<br /> Viel Erfolg bei deiner nächsten Teilnahme.
						</p>
					)}

					{data === "INVALID_TERMS" && (
						<p>
							Leider entspricht deine Teilnahme nicht den{" "}
							<NavLink
								to={GLOBAL_CONFIG.Route.terms}
								exact
								data-event="go_to_terms"
								data-category="Confirmation Page"
								data-action="Go to page"
								data-label="ENG_CP_OTHER"
								onClick={appGoogleTracking.processEventCTANavLink}
							>
								<span
									style={{ textDecoration: "underline" }}
									className="btn__text"
								>
									Teilnahmebedingungen
								</span>
							</NavLink>{" "}
							dieser Gewinnaktion.
							<br />
							Viel Erfolg bei deiner nächsten Teilnahme.
						</p>
					)}

					{data === "BLACKLISTED_EMAIL" && (
						<p>Ihre E-Mail-Adresse ist ungültig.</p>
					)}

					{data === "CAPTCHA_ERROR" && <p>CAPTCHA FEHLER</p>}

					{data === "AN_ERROR_HAS_OCCURED" && <p>EIN FEHLER IST AUFGETRETEN</p>}
				</div>
				<div className='tender-gift'>
						<div className='s-content-title'>
							<h1>Milka – das zarteste Geschenk</h1>  
						</div>
						{/* <img src={'/resources/images/xmas/geschenk-holding.png'} alt="Milka tender gift" /> */}
						<img src={t('Page.GameConfirmation.tenderGift')} alt="Milka tender gift" />
					</div>
					<a href='https://das-zarteste-geschenk.milka.de/' target='_blank' 
						data-event="return_to_homepage" 
						data-category="Confirmation Page" 
						data-action="Go to page" 
						data-label="ENG_CP_OTHER" 
						onClick={appGoogleTracking.processEventCTANavLink}>
						<div className={window.PROMOTION_CAMPAIGN === "netto" ? "btn__container btn--primary btn--hover" : "btn__container btn--secondary-lilac btn--hover"}>
							<span className="btn__text">{parse(t('Page.GameConfirmation.button'))}</span>
						</div>
					</a>
					<hr className="purple-line-bottom-text"></hr>
					<GiftContentFooter options={options} />
			</div>
		);
	}
}

export default withTranslation()(ConfirmationError);
