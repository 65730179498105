import React from 'react';
import { NavLink } from 'react-router-dom';
import GLOBAL_CONFIG from '../../../config/config';
import appGoogleTracking from '../../../modules/googletracking';

const GiftContentFooter = (props) => { 

    let btnContent = null,
        { btnShow, hrShow, classModifier, options } = props;

    if((!!props.options && props.options.btnShow === true) || btnShow){
        btnContent = (
            <NavLink to={GLOBAL_CONFIG.Route.home} exact data-event="info-click" data-category="Informational Action" data-action="Start Page" data-label={window.META_TITLE} onClick={appGoogleTracking.processEventCTANavLink}>
                <div className="btn__container btn--secondary-lilac btn--hover">
                    <span className="btn__text">Zurück zur Startseite</span>
                </div>
            </NavLink>
        );   
    }

    let classname = ((!!props.options && props.options.hrShow === true) || hrShow) ? 'content-gift-footer hr' : 'content-gift-footer';

    let dataCategory = "";
    let dataLabel = "";
    let dataEvent = "";
    if(window.PROMOTION_CAMPAIGN === "kaufland"){
        switch( window.location.pathname){
            case GLOBAL_CONFIG.Route.home:
                dataEvent = "upload_receipt link"
                dataCategory = 'Start Page';
                dataLabel = 'ENG_SP_OTHER';
                break;
            case GLOBAL_CONFIG.Route.participation:
                dataEvent = "upload_receipt_link"
                dataCategory = 'Take Part Page';
                dataLabel = 'GAME_TPP_PART';
                break;
            case GLOBAL_CONFIG.Route.confirmation:
                dataEvent = "upload_receipt_link"
                dataCategory = 'Confirmation Page';
                dataLabel = 'ENG_CP_OTHER';
                break;
        }
    }else{
        switch( window.location.pathname){
            case GLOBAL_CONFIG.Route.home:
                dataEvent = "upload_receipt link"
                dataCategory = 'Start Page';
                dataLabel = 'GAME_SP_PART';
                break;
            case GLOBAL_CONFIG.Route.participation:
                dataCategory = 'Take Part Page';
                dataEvent = "upload_receipt_link"
                dataLabel = 'GAME_TPP_PART';
                break;
            case GLOBAL_CONFIG.Route.confirmation:
                dataEvent = "upload_receipt_link"
                dataCategory = 'Confirmation Page';
                dataLabel = 'GAME_CP_PART';
                break;
            case GLOBAL_CONFIG.Route.participationError:
                dataEvent = "upload_receipt_link"
                dataCategory = 'Confirmation Page';
                dataLabel = 'GAME_CP_PART';
                break;
        }
       

    }
    return (
        <div className={!!classModifier ? `${classname} ${classModifier}` : classname}>
            {btnContent}
            <div className="content-faq s-content-copy marge-bottom">
                <div className="s-content-copy__item footer">
                    <p className="headings">HABT IHR NOCH FRAGEN ZUR AKTION?</p>
                    <p><NavLink to={GLOBAL_CONFIG.Route.faq} exact 
                    data-event={dataEvent}
                    data-category={dataCategory} 
                    data-action="Click on Link" 
                    data-label={dataLabel}
                    onClick={appGoogleTracking.processEventCTANavLink}>Hier</NavLink> geht es zu den FAQ</p>
                </div>
            </div>
        </div>
    );
}

export default GiftContentFooter;
